<template>
    <div>
        <TableActions
            :loading="loading"
            :selected-items="selectedItems"
            @add-new="addNewAddress" />
        <v-data-table
            :headers="headers"
            fixed-header
            :items="addresses"
            :loading="loading"
            hide-default-footer
            disable-pagination
            item-key="address"
            class="data-table-full">
            <template #no-data>
                <span>{{ $t('info.noSavedAddresses') }} -</span>
                <span
                    class="ml-1 color pointer text-links"
                    @click="addNewAddress">{{ $t('addAddress') }}</span>
            </template>
            <template #item="{ item: address }">
                <tr>
                    <td>
                        {{ address.addressLine1 || '' }}
                    </td>
                    <td>
                        {{ address.addressLine2 || '' }}
                    </td>
                    <td>
                        {{ address.city || '' }}{{ (address.city && address.state) ? ', ' : '' }}{{ address.state || '' }}
                    </td>
                    <td>
                        {{ address.postalCode || '' }}
                    </td>
                    <td>
                        {{ address.country || '' }}
                    </td>
                    <td>
                        {{ address.phone || '' }}
                    </td>
                    <td>
                        {{ address.email || '' }}
                    </td>
                    <td>
                        <tag-listing
                            :tags="tags(address)"
                            small-tags
                            disable-delete-tag
                            :show-tags-count="1" />
                    </td>
                    <td>
                        <v-menu
                            transition="slide-y-transition"
                            offset-y>
                            <template #activator="{ on, attrs }">
                                <div
                                    v-bind="attrs"
                                    v-on="on">
                                    <v-icon>
                                        mdi-dots-horizontal
                                    </v-icon>
                                </div>
                            </template>
                            <v-list>
                                <v-list-item
                                    @click="editAddress(address)">
                                    <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    class="error--text"
                                    @click="openDelModal(address)">
                                    <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <AddressModal ref="addressModal" />

        <AlertDeleteModal
            ref="delModal"
            :title="$t('billings.deleteAddressConfirmationTitle')"
            :delete-msg="$t('billings.deleteAddressConfirmationMessage')"
            :item-key-name="''"
            :on-delete="onDeleteAddress"
            :on-cancel="onDeleteClose" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import AddressModal from 'pages/settings/addressManagement/AddressModal.vue'
    import TableActions from 'components/alerts/TableActions.vue'
    import AlertDeleteModal from 'components/alerts/AlertDeleteModal.vue'

    export default {
        name: 'AddressTable',
        components: {
            AddressModal,
            TableActions,
            AlertDeleteModal,
        },
        props: {
            addresses: { type: Array, required: true, },
            loading: { type: Boolean, default: false, },
        },
        data () {
            return {
                headers: [
                    { text: this.$t('billings.address1'), value: 'addressLine1',sortable: false,},
                    { text: this.$t('billings.address2'), value: 'addressLine2', sortable: false, },
                    { text: this.$t('billings.city') + ', ' + this.$t('billings.stateProvince'), value: 'city', sortable: false, },
                    { text: this.$t('billings.zipCode'), value: 'postalCode', sortable: false, },
                    { text: this.$t('billings.country'), value: 'country', sortable: false, },
                    { text: this.$t('billings.phoneNumber'), value: 'phone', sortable: false, },
                    { text: this.$t('email'), value: 'email', sortable: false, },
                    { text: this.$t('type'), value: 'tags', sortable: false, },
                    { text: '', value: 'menu', width: '30px', sortable: false, }
                ],
                selectedItems: [],
                addressForDelete: null,
            }
        },
        computed: {
            ...mapGetters([
                'deleteAddressSuccess'
            ]),
        },
        methods: {
            ...mapActions([
                'fetchAddresses',
                'deleteAddress'
            ]),
            tags (address) {
                let allTags = []
                allTags.push({
                    type: 'custom',
                    text: address.addressType,
                    cortexFormat: `Type: ${address.addressType}`,
                })
                return allTags
            },
            openAddressModal ({ mode, address, }) {
                this.$refs.addressModal.open({ mode, address, })
            },
            editAddress(address) {
                this.openAddressModal({ mode: 'edit', address, })
            },
            onDeleteClose() {
                this.addressForDelete = null
                this.$refs.delModal.isDialogOpen = false
            },
            async onDeleteAddress() {
                await this.deleteAddress(this.addressForDelete?.id)
                this.$refs.delModal.isDialogOpen = false
                if (this.deleteAddressSuccess) {
                    this.$store.commit('showMessage', { text: this.$t('billings.addressDeletedSuccessfully'), type: '#03a128', })
                } else {
                    this.$store.commit('showMessage', { text: this.$t('errors.deleteAddress'), type: this.$config.messageType.ERROR, })
                }
                await this.fetchAddresses()
            },
            openDelModal (address) {
                this.addressForDelete = address
                this.$refs.delModal.open(this.selectedItems)
            },
            addNewAddress () {
                this.openAddressModal({ mode: 'create', })
            },
        },
    }
</script>

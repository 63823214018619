import Vue from 'vue'
import Vuex from 'vuex'
import user from 'store/user'
import services from 'store/services'
import alerts from 'store/alerts'
import healthCheck from 'store/healthCheck'
import serverDetails from 'store/serverDetails'
import auth from 'store/auth'
import rules from 'store/alerts/rules'
import channels from 'store/alerts/channels'
import allowlist from 'store/allowlist'
import serviceCredentials from 'store/serviceCredentials'
import manageServices from 'store/manageServices'
import billing from 'store/billing'
import organizations from 'store/organizations'
import configManager from 'store/configManager'
import invites from 'store/invites'
import policies from 'store/policies'
import users from 'store/users'
import notifications from 'store/notifications'
import alertsNotifications from 'store/alerts/notifications'
import tags from 'store/alerts/tags'
import silences from 'store/alerts/silences'
import backups from 'store/backups'
import copilot from 'store/copilot.js'
import i18n from 'plugins/i18n'
import { copyToClipboard } from 'helpers/'

Vue.use(Vuex)

export default new Vuex.Store({
    namespaced: true,
    state: {
        message: {
            status: false,
            text: '',
            type: 'info',
        },
        overlay: false,
        showOldPortalBanner: true,
    },
    mutations: {
        showMessage(state, { text, type, }) {
            // Suppress error messages when app is unhealthy
            if (type === 'error' && !state.healthCheck.isHealthy) return
            state.message.status = true
            state.message.text = text
            state.message.type = type
        },
        showOverlay(state, type) {
            state.overlay = type
        },
        hideOverlay(state) {
            state.overlay = false
        },
        showOldPortalBanner(state, flag) {
            state.showOldPortalBanner = flag
        },
    },
    actions: {
        async copyToClipboard({ commit, }, stringToCopy) {
            try {
                await copyToClipboard(stringToCopy)
                commit('showMessage', {
                    text: i18n.t('success.copyToClipboard'),
                    type: 'success',
                })
            } catch (_) {
                commit('showMessage', {
                    text: i18n.t('errors.unknown'),
                    type: 'error',
                })
            }
        },
        setOldPortalBannerVisibility({ commit, }, isVisible) {
            commit('showOldPortalBanner', isVisible)
        },
    },
    getters: {
        isOldPortalBannerVisible: (state) => state.showOldPortalBanner,
    },
    modules: {
        user,
        services,
        alerts,
        rules,
        channels,
        healthCheck,
        serverDetails,
        auth,
        allowlist,
        serviceCredentials,
        manageServices,
        billing,
        organizations,
        configManager,
        invites,
        policies,
        users,
        notifications,
        alertsNotifications,
        tags,
        silences,
        backups,
        copilot,
    },
})

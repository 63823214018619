<template>
    <v-select
        :class="['std std--dropdown select-dropdown']"
        :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
        :items="agents"
        item-text="name"
        item-value="id"
        :value="selectedAgent?.id"
        :loading="areAgentsLoading"
        :disabled="areAgentsLoading"
        :error="agentsError"
        height="36"
        outlined
        attach
        single-line
        @change="changeAgent" />
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        name: 'CopilotAgentsMenu',
        computed: {
            ...mapGetters([
                'agents',
                'agentsError',
                'areAgentsLoading',
                'selectedAgent'
            ]),
        },
        watch: {
            agents(agentsList) {
                if (agentsList && agentsList.length) {
                    this.selectAgent(agentsList[0].id)
                }
            },
        },
        methods: {
            ...mapActions([
                'selectAgent'
            ]),
            changeAgent(agentId) {
                this.selectAgent(agentId)
            },
        },
    }
</script>

<style lang="scss" scoped>
    .starters {
        width: 100%;
        max-width: 768px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        .v-card {
            display: flex;
            border-radius: 10px;
            min-height: 160px;
            align-items: center;
            justify-content: center;
        }
    }
</style>

<template>
    <div
        v-if="questions.length"
        class="starters">
        <v-card
            v-for="(q, i) in questions"
            :key="i"
            class="pa-4 pointer"
            outlined
            @click="selectQuestion(q)">
            <div>
                {{ q }}
            </div>
        </v-card>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'CopilotChatStarterQuestions',
        computed: {
            ...mapGetters([
                'selectedAgent'
            ]),
            questions() {
                if (this.selectedAgent?.starterQuestions) {
                    return this.selectedAgent?.starterQuestions
                } else {
                    return []
                }
            },
        },
        methods: {
            selectQuestion(question) {
                this.$emit('prompt', question)
            },
        },
    }
</script>

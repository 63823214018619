import to from 'await-to-js'
import i18n from 'plugins/i18n.js'
import common from 'config/common.js'
import {
    getDatasources,
    getDatasource,
    addDatasource,
    editDatasource,
    removeDatasource,
    getAgents,
    getChatSessions,
    getChatSession,
    removeChatSession,
    createChatSession,
    sendChatMessage
} from 'services/copilot.js'

export default {
    state: {
        datasources: [],
        datasourcesErrorMsg: false,
        datasourcesLoading: false,
        selectedDatasourceId: null,
        selectedDatasourceSchema: null,
        agents: [],
        agentsErrorMsg: false,
        agentsLoading: false,
        selectedAgentId: false,
        context: [],
        chats: [],
        chatsErrorMsg: false,
        chatsLoading: false,
        chatLoading: false,
        selectedChatId: null,
    },
    mutations: {
        setDatasources(state, datasourcesList) {
            state.datasources = datasourcesList
        },
        setDatasourceSchemas(state, datasourceData) {
            const datasource = state.datasources.find(datasource => datasource.id === datasourceData.id)
            if (datasource && datasourceData.schemas) {
                datasource.schemas = datasourceData.schemas
            }
        },
        addDatasource(state, datasource) {
            state.datasources.push(datasource)
        },
        editDatasource(state, { datasourceId, data, }) {
            const datasource = state.datasources.find(datasource => datasource.id === datasourceId)
            if (datasource) {
                datasource.name = data.name
                datasource.hostname = data.hostname
                datasource.port = data.port
                datasource.username = data.username
            }
        },
        removeDatasource(state, datasourceId) {
            const datasourceIndex = state.datasources.findIndex(datasource => datasource.id === datasourceId)
            if (datasourceIndex !== -1) {
                state.datasources.splice(datasourceIndex, 1)
                if (state.selectedDatasourceId === datasourceId) {
                    state.selectedDatasourceId = state.datasources[0]
                }
            }
        },
        setDatasourcesError(state, error) {
            state.datasourcesErrorMsg = error
        },
        setDatasourcesLoading(state, status) {
            state.datasourcesLoading = status
        },
        setSelectedDatasourceId(state, datasourceId) {
            state.selectedDatasourceId = datasourceId
        },
        setSelectedDatasourceSchema(state, schema) {
            state.selectedDatasourceSchema = schema
        },
        setAgents(state, agentsList) {
            state.agents = agentsList
        },
        setAgentsError(state, error) {
            state.agentsErrorMsg = error
        },
        setAgentsLoading(state, status) {
            state.agentsLoading = status
        },
        setSelectedAgentId(state, agentId) {
            state.selectedAgentId = agentId
        },
        setChats(state, chatsList) {
            state.chats = chatsList
        },
        addChat(state, chat) {
            state.chats.push(chat)
        },
        removeChat(state, chatId) {
            const chatIndex = state.chats.findIndex(chat => chat.id === chatId)
            if (chatIndex !== -1) {
                state.chats.splice(chatIndex, 1)
                if (state.selectedChatId === chatId) {
                    state.selectedChatId = state.chats[0]
                }
            }
        },
        setChatsError(state, error) {
            state.chatsErrorMsg = error
        },
        setChatsLoading(state, status) {
            state.chatsLoading = status
        },
        setChatLoading(state, status) {
            state.chatLoading = status
        },
        setSelectedChatId(state, chatId) {
            state.selectedChatId = chatId
        },
        pushChatSessionEntry(state, { chatId, entry, }) {
            const chat = state.chats.find(chat => chat.id === chatId)
            if (chat && entry) {
                chat.entries.push(entry)
            }
        },
        setChatSessionEntries(state, chatData) {
            const chat = state.chats.find(chat => chat.id === chatData.id)
            if (chat && chatData.entries) {
                chat.entries = chatData.entries
            }
        },
    },
    actions: {
        async fetchDatasources({ commit, }) {
            commit('setDatasourcesLoading', true)
            const [error, response] = await to(getDatasources())
            if (error) {
                commit('setDatasourcesError', true)
                commit('showMessage', { text: i18n.t('errors.fetchDatasources'), type: common.messageType.ERROR, })
            } else {
                commit('setDatasources', response)
                commit('setDatasourcesError', false)
            }
            commit('setDatasourcesLoading', false)
        },
        async fetchDatasource({ commit, }, datasourceId) {
            commit('setDatasourcesLoading', true)
            const [error, response] = await to(getDatasource(datasourceId))
            if (error) {
                commit('setDatasourcesError', true)
                commit('showMessage', { text: i18n.t('errors.fetchDatasources'), type: common.messageType.ERROR, })
            } else {
                commit('setDatasourceSchemas', response)
                commit('setDatasourcesError', false)
            }
            commit('setDatasourcesLoading', false)
            return response
        },
        async selectDatasource({ commit, dispatch, }, datasourceId) {
            const datasource = await dispatch('fetchDatasource', datasourceId)
            if (datasource?.schemas[0]?.name) {
                commit('setSelectedDatasourceSchema', datasource?.schemas[0]?.name)
            }
            commit('setSelectedDatasourceId', datasourceId)
        },
        selectDatasourceSchema({ commit, }, schema) {
            commit('setSelectedDatasourceSchema', schema)
        },
        async addDatasource({ commit, }, datasource) {
            const [error, response] = await to(addDatasource(datasource))
            if (error) {
                const errorMsg = error?.response?.status === 400 ? i18n.t('errors.connectDatasource') : i18n.t('errors.saveDatasource')
                commit('showMessage', { text: errorMsg, type: common.messageType.ERROR, })
            } else {
                commit('addDatasource', response)
                commit('setSelectedDatasourceId', response.id)
            }
        },
        async editDatasource({ commit, }, { datasourceId, data, }) {
            const [error, response] = await to(editDatasource(datasourceId, data))
            if (error) {
                const errorMsg = error?.response?.status === 400 ? i18n.t('errors.connectDatasource') : i18n.t('errors.saveDatasource')
                commit('showMessage', { text: errorMsg, type: common.messageType.ERROR, })
            } else {
                commit('editDatasource', { datasourceId, data: response, })
            }
        },
        async removeDatasource({ commit, }, datasourceId) {
            const [error] = await to(removeDatasource(datasourceId))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.removeDatasource'), type: common.messageType.ERROR, })
            } else {
                commit('removeDatasource', datasourceId)
            }
        },
        async fetchAgents({ commit, }) {
            commit('setAgentsLoading', true)
            const [error, response] = await to(getAgents())
            if (error) {
                commit('setAgentsError', true)
                commit('showMessage', { text: i18n.t('errors.fetchAgents'), type: common.messageType.ERROR, })
            } else {
                commit('setAgents', response)
                commit('setAgentsError', false)
            }
            commit('setAgentsLoading', false)
        },
        selectAgent({ commit, }, agentId) {
            commit('setSelectedChatId', null)
            commit('setSelectedAgentId', agentId)
        },
        async fetchChatSessions({ commit, } ) {
            commit('setChatsLoading', true)
            const [error, response] = await to(getChatSessions())
            if (error) {
                commit('setChatsError', true)
                commit('showMessage', { text: i18n.t('errors.fetchChatSessions'), type: common.messageType.ERROR, })
            } else {
                commit('setChats', response)
                commit('setChatsError', false)
            }
            commit('setChatsLoading', false)
        },
        async selectChatSession({ commit, dispatch, }, chatSessionId) {
            commit('setSelectedChatId', chatSessionId)
            await dispatch('getChatSession', chatSessionId)
        },
        async newChatSession({ state, commit, }, { agentId, datasourceId, datasourceSchema, }) {
            const config = {}
            // TODO: Temp fix
            if (datasourceId && state.selectedAgentId !== '89256c79-bdfe-435a-8729-4d1e9a9ebca2') {
                config.datasourceId = datasourceId
            }
            if (datasourceId && state.selectedAgentId === 'afe84b79-f29f-4f9d-8aa3-a7363df3628c') {
                config.dbName = datasourceSchema
            }
            const [error, response] = await to(createChatSession(agentId, Object.keys(config).length ? config : null))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.createChatSession'), type: common.messageType.ERROR, })
            } else {
                commit('addChat', response)
                commit('setSelectedChatId', response.id)
            }
        },
        async getChatSession({ commit, }, chatSessionId) {
            commit('setChatLoading', true)
            const [error, response] = await to(getChatSession(chatSessionId))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.getChatSession'), type: common.messageType.ERROR, })
            } else {
                commit('setChatSessionEntries', response)
            }
            commit('setChatLoading', false)
        },
        async removeChatSession({ commit, }, chatSessionId) {
            commit('setChatLoading', true)
            const [error] = await to(removeChatSession(chatSessionId))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.removeChatSession'), type: common.messageType.ERROR, })
            } else {
                commit('removeChat', chatSessionId)
            }
            commit('setChatLoading', false)
        },
        async newChatMessage({ commit, }, { prompt, chatSessionId, }) {
            commit('setChatLoading', true)
            commit('pushChatSessionEntry', {
                chatId: chatSessionId,
                entry: {
                    chat: { prompt, response: null,},
                },
            })
            const [error, response] = await to(sendChatMessage(prompt, chatSessionId))
            if (error) {
                commit('showMessage', { text: i18n.t('errors.sendChatSessionMessage'), type: common.messageType.ERROR, })
            } else {
                commit('setChatSessionEntries', response)
            }
            commit('setChatLoading', false)
        },
    },
    getters: {
        datasources: state => state.datasources,
        datasourcesError: state => state.datasourcesErrorMsg,
        areDatasourcesLoading: state => state.datasourcesLoading,
        selectedDatasource: state => state.datasources.find(datasource => datasource.id === state.selectedDatasourceId),
        selectedDatasourceSchema: state => state.selectedDatasourceSchema,
        agents: state => state.agents,
        agentsError: state => state.agentsErrorMsg,
        areAgentsLoading: state => state.agentsLoading,
        selectedAgent: state => state.agents.find(agent => agent.id === state.selectedAgentId),
        chatSessions: state => state.chats,
        agentChatSessions: state => state.chats.filter(chat => chat.agentId === state.selectedAgentId),
        selectedChatSession: state => state.chats.find(chat => chat.id === state.selectedChatId),
        chatSessionsError: state => state.chatsErrorMsg,
        areChatSessionsLoading: state => state.chatsLoading,
        isChatSessionLoading: state => state.chatLoading,
    },
}

import Settings from 'pages/Settings'
import NotificationsChannels from 'pages/settings/NotificationsChannels'
import Firewall from 'pages/settings/Firewall'
import ConfigurationManagerRoute from 'router/configurationManager'
import AddressManagement from 'pages/settings/addressManagement/AddressManagement'
import TaxManagement from 'pages/settings/taxManagement/TaxManagement'
import ContactManagement from 'pages/settings/contactManagement/ContactManagement'
import UserManagement from 'pages/settings/UserManagement'
import Policies from 'pages/settings/Policies'
import SecureAccess from 'pages/settings/SecureAccess'
import Notifications from 'pages/settings/Notifications'
import Organisation from 'pages/settings/Organisation'

export default [
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
            requiresAuth: true,
            title: 'Settings',
        },
    },
    {
        path: '/settings/firewall',
        name: 'Firewall',
        component: Firewall,
        meta: {
            title: 'Firewall',
        },
    },
    ...ConfigurationManagerRoute,
    {
        path: '/settings/user-management',
        name: 'User Management',
        component: UserManagement,
        meta: {
            title: 'UserManagement',
        },
    },
    {
        path: '/settings/notification-channels',
        name: 'Notification channels',
        component: NotificationsChannels,
        meta: {
            title: 'NotificationsChannels',
        },
    },
    {
        path: '/settings/policies',
        name: 'Policies',
        component: Policies,
        meta: {
            title: 'Policies',
        },
    },
    {
        path: '/settings/secure-access',
        name: 'Secure Access',
        component: SecureAccess,
        meta: {
            title: 'Secure Access',
        },
    },
    {
        path: '/settings/notifications',
        name: 'Notifications',
        component: Notifications,
        meta: {
            title: 'Notifications',
        },
    },
    {
        path: '/settings/organization',
        name: 'Organisation',
        component: Organisation,
        meta: {
            title: 'Organisation',
        },
    },
    {
        path: '/settings/address-management',
        name: 'Address Management',
        component: AddressManagement,
        meta: {
            title: 'Address Management',
        },
    },
    {
        path: '/settings/tax-management',
        name: 'Tax Management',
        component: TaxManagement,
        meta: {
            title: 'Tax Management',
        },
    },
    {
        path: '/settings/contact-management',
        name: 'Contact Management',
        component: ContactManagement,
        meta: {
            title: 'Contact Management',
        },
    }
]

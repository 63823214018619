<template>
    <v-tooltip
        open-delay="400"
        max-width="400"
        transition="scroll-x-transition"
        right>
        <template #activator="{ on }">
            <v-icon
                dense
                v-on="on">
                mdi-help-circle-outline
            </v-icon>
        </template>
        <span>{{ helpText }}</span>
    </v-tooltip>
</template>

<script>
    export default {
        name: 'CopilotInfoIcon',
        props: {
            helpText: {
                type: String,
                required: true,
            },
        },
    }
</script>

import copilotAPI from 'api/copilot.js'
import { camelize, convertToSnakeCase } from 'helpers/index.js'

// TODO: Temporary hardcoded in the UI until API support is there
const starterQuestions = {
    '89256c79-bdfe-435a-8729-4d1e9a9ebca2': [
        'Get Connected - From a Java App?',
        'Get Connected - From a Node.js app',
        'Get Connected - Using DBeaver tool',
        'Load data - Create and load using Synthetic Data',
        'Load data - Create table and load CSV',
        'Migration - Live migration to SkySQL?',
        'Generate SQL - generate Schema from sample JSON',
        'DR - setup a remote replica for DR',
        'Backup - schedule a backup every 6 hours?',
        'Tune, Learn MariaDB - how I can tune Query performance?'
    ],
    'afe84b79-f29f-4f9d-8aa3-a7363df3628c': [
        'Summarize my db schemas.',
        'Show me top 5 customers that placed the most orders.',
        'Show me the total sales revenue per year.',
        'Show me the total orders along with revenue for each country.'
    ],
    'ce4f719f-c537-489a-b5c3-08e111f5736a': [
        'Produce a report on the health of my server.',
        'Find the queries taking the most time?',
        'Analyze the slowest query ',
        'Analyze the slowest query and provide specific optimization suggestions.',
        'Fetch all currently running queries.',
        'Find which users have been the most active?',
        'Analyze the state of my InnoDB storage engine. Any insights?',
        'How many threads are active and what is the maximum allowed?',
        'Fetch the status of my replication?'
    ],
}

function mapDatasources(datasources) {
    return datasources.map(datasource => {
        return {
            ...datasource,
            schemas: [],
        }
    })
}

function mapAgents(agents) {
    return agents.map(agent => {
        return {
            ...agent,
            starterQuestions: starterQuestions[agent.id],
        }
    })
}

function mapChatSessions(chatSessions) {
    return chatSessions.map(chatSession => {
        return {
            ...chatSession,
            entries: [],
        }
    })
}

function mapChatSession(chatData) {
    chatData.entries.sort((a, b) => new Date(a.created_on) - new Date(b.created_on))
    return chatData
}

export async function getAgents () {
    return copilotAPI.get('agent/').then(response => camelize(mapAgents(response.data)))
}

export async function getDatasources () {
    return copilotAPI.get('data-source/').then(response => camelize(mapDatasources(response.data)))
}

export async function getDatasource (datasourceId) {
    return copilotAPI.get(`data-source/${datasourceId}`).then(response => camelize(response.data))
}

export async function addDatasource (datasource) {
    return copilotAPI.post('data-source/', datasource).then(response => camelize(response.data))
}

export async function editDatasource (datasourceId, data) {
    return copilotAPI.patch(`data-source/${datasourceId}`, data).then(response => camelize(response.data))
}

export async function removeDatasource (datasourceId) {
    return copilotAPI.delete(`data-source/${datasourceId}`).then(response => camelize(response.data))
}

export async function getChatSessions () {
    return copilotAPI.get('session/').then(response => camelize(mapChatSessions(response.data)))
}

export async function getChatSession (chatSessionId) {
    return copilotAPI.get(`session/${chatSessionId}`).then(response => camelize(mapChatSession(response.data)))
}

export async function removeChatSession (chatSessionId) {
    return copilotAPI.delete(`session/${chatSessionId}`).then(response => camelize(response.data))
}

export async function createChatSession (agentId, config) {
    const payload = { agentId, }
    if (config) payload.config = config
    return copilotAPI.post('session/', convertToSnakeCase(payload)).then(response => camelize(response.data))
}

export async function sendChatMessage (prompt, chatSessionId) {
    return copilotAPI.put(`session/${chatSessionId}`, { prompt, }).then(response => camelize(mapChatSession(response.data)))
}

<style lang="scss" scoped>
    .copilot {
        position: relative;
        padding-left: 360px !important;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .sidebar {
        position: absolute;
        left: 0;
        top: 0;
        width: 340px;
        height: 100%;
        padding: 48px 12px;
        border-right: 1px solid #e8eef1;
    }
    .toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .docs {
        position: absolute;
        left: 12px;
        bottom: 12px;
    }
    #copilot-messages {
        flex-grow: 1;
        padding: 24px 0;
        width: 100%;
        max-width: 768px;
        margin: 0 auto;
        height: calc(100% - 600px);
        overflow-y: auto;
    }
    .prompt-input {
        width: 100%;
        max-width: 768px;
        margin: 0 auto;
    }
</style>

<template>
    <v-container
        class="copilot ma-0 pa-12"
        fluid>
        <div class="sidebar">
            <div class="text-overline toolbar">
                <div>
                    {{ $tc('copilot.datasource', 1) }}
                    <CopilotInfoIcon :help-text="$t('copilot.datasourceInfo')" />
                </div>
                <div>
                    <v-btn
                        color="info"
                        x-small
                        text
                        @click="addDatasource">
                        <v-icon left>
                            mdi-database-plus
                        </v-icon>
                        {{ $t('add') }}
                    </v-btn>
                    <v-btn
                        color="primary"
                        x-small
                        text
                        @click="manageDatasources">
                        <v-icon left>
                            mdi-cog
                        </v-icon>
                        {{ $t('manage') }}
                    </v-btn>
                </div>
            </div>
            <div>
                <CopilotDatasourcesMenu />
            </div>
            <div class="text-overline mt-6 toolbar">
                <div>
                    {{ $tc('copilot.agent', 1) }}
                    <CopilotInfoIcon :help-text="currentAgentDescription" />
                </div>
                <div>
                    <v-btn
                        color="info"
                        x-small
                        text
                        disabled>
                        <v-icon left>
                            mdi-layers-plus
                        </v-icon>
                        {{ $t('create') }}
                    </v-btn>
                    <v-btn
                        color="primary"
                        x-small
                        text
                        disabled>
                        <v-icon left>
                            mdi-cog
                        </v-icon>
                        {{ $t('manage') }}
                    </v-btn>
                </div>
            </div>
            <div>
                <CopilotAgentsMenu />
            </div>
            <div
                v-if="isContextVisible"
                class="text-overline mt-8 toolbar">
                <div>
                    {{ $t('copilot.context') }}
                    <CopilotInfoIcon :help-text="$t('copilot.contextInfo')" />
                </div>
                <v-btn
                    color="success"
                    x-small
                    text
                    disabled>
                    <v-icon left>
                        mdi-auto-fix
                    </v-icon>
                    Enhance
                </v-btn>
            </div>
            <CopilotContextMenu v-if="isContextVisible" />

            <v-btn
                class="docs"
                color="primary"
                small
                text
                @click="openDocs">
                <v-icon left>
                    mdi-text-box-multiple
                </v-icon>
                {{ $t('documentation') }}
            </v-btn>
        </div>

        <CopilotChatsMenu />

        <CopilotChatStarterQuestions
            v-if="!chatEntries.length"
            @prompt="sendPrompt" />
        <div
            v-else
            id="copilot-messages">
            <CopilotChatMessage
                v-for="(entry, i) in chatEntries"
                :key="i"
                :message="entry.chat"
                class="copilot-message" />
        </div>

        <div class="prompt-input">
            <v-textarea
                v-model="prompt"
                :loading="isChatSessionLoading"
                label=""
                rows="1"
                :placeholder="$t('copilot.promptPlaceholder')"
                auto-grow
                autofocus
                outlined
                hide-details
                @keyup.stop.prevent.enter="sendPrompt(prompt)">
                <template #append>
                    <v-btn
                        color="primary"
                        :disabled="isChatSessionLoading || (!prompt && !prompt.length)"
                        icon
                        @click="sendPrompt(prompt)">
                        <v-icon>mdi-send</v-icon>
                    </v-btn>
                </template>
            </v-textarea>

            <CopilotDatasourceModal ref="datasourceModal" />
            <CopilotDatasourcesManageModal
                ref="datasourcesManageModal"
                @addDatasource="addDatasource"
                @editDatasource="editDatasource"
                @removeDatasource="confirmDatasourceRemoval" />
            <ConfirmationModal
                ref="confirmDatasourceDeleteModal"
                message="confirmations.deleteDatasource"
                :on-confirm="() => removeDatasource(confirmedDatasourceId)" />
        </div>
    </v-container>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import CopilotInfoIcon from 'components/copilot/InfoIcon.vue'
    import CopilotDatasourcesMenu from 'components/copilot/DatasourcesMenu.vue'
    import CopilotDatasourceModal from 'components/copilot/DatasourceModal.vue'
    import CopilotDatasourcesManageModal from 'components/copilot/DatasourcesManageModal.vue'
    import CopilotAgentsMenu from 'components/copilot/AgentsMenu.vue'
    import CopilotContextMenu from 'components/copilot/ContextMenu.vue'
    import CopilotChatsMenu from 'components/copilot/ChatsMenu.vue'
    import CopilotChatStarterQuestions from 'components/copilot/ChatStarterQuestions.vue'
    import CopilotChatMessage from 'components/copilot/ChatMessage.vue'
    import ConfirmationModal from 'components/common/ConfirmationModal.vue'

    export default {
        name: 'CopilotPage',
        components: {
            ConfirmationModal,
            CopilotInfoIcon,
            CopilotDatasourcesMenu,
            CopilotDatasourceModal,
            CopilotDatasourcesManageModal,
            CopilotAgentsMenu,
            CopilotContextMenu,
            CopilotChatsMenu,
            CopilotChatStarterQuestions,
            CopilotChatMessage,
        },
        data() {
            return {
                prompt: '',
                confirmedDatasourceId: null,
            }
        },
        computed: {
            ...mapGetters([
                'agents',
                'selectedAgent',
                'selectedDatasource',
                'selectedDatasourceSchema',
                'selectedChatSession',
                'isChatSessionLoading',
                'agentChatSessions'
            ]),
            currentAgentDescription() {
                return this.selectedAgent?.description || this.$t('copilot.agentInfo')
            },
            chatEntries() {
                return this.selectedChatSession?.entries || []
            },
            isContextVisible() {
                return this.selectedAgent?.id !== '89256c79-bdfe-435a-8729-4d1e9a9ebca2'
            },
        },
        watch: {
            agentChatSessions(sessions){
                if (!sessions.length && this.selectedAgent?.id) {
                    this.newChatSession({
                        agentId: this.selectedAgent.id,
                        datasourceId: this.selectedDatasource.id,
                        datasourceSchema: this.selectedDatasourceSchema,
                    })
                }
            },
            chatEntries(entries) {
                if (entries && entries.length) {
                    // TODO: Temp fix
                    setTimeout(() => {
                        const items = document.querySelectorAll('#copilot-messages .copilot-message')
                        if (items.length) {
                            items[items.length - 1].scrollIntoView()
                        }
                    }, 0)
                }
            },
        },
        methods: {
            ...mapActions([
                'fetchDatasources',
                'fetchAgents',
                'fetchChatSessions',
                'newChatMessage',
                'newChatSession',
                'removeDatasource'
            ]),
            addDatasource() {
                this.$refs.datasourceModal.open()
            },
            editDatasource(datasourceId) {
                this.$refs.datasourceModal.open(datasourceId)
            },
            confirmDatasourceRemoval(datasourceId) {
                this.confirmedDatasourceId = datasourceId
                this.$refs.confirmDatasourceDeleteModal.open()
            },
            manageDatasources() {
                this.$refs.datasourcesManageModal.open()
            },
            openDocs () {
                window.open(`${this.$config.DOCS_URL}SkyCopilot Guide`)
            },
            async sendPrompt (prompt) {
                if (!prompt.length) return
                this.newChatMessage({
                    prompt,
                    chatSessionId: this.selectedChatSession.id,
                })
                this.prompt = ''
            },
        },
        async created() {
            this.fetchDatasources()
            this.fetchAgents()
            this.fetchChatSessions()
        },
    }
</script>

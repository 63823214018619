<template>
    <div>
        <TableActions
            :loading="loading"
            :selected-items="selectedItems"
            @add-new="addNewTax" />
        <v-data-table
            :headers="headers"
            fixed-header
            :items="taxes"
            :loading="loading"
            hide-default-footer
            disable-pagination
            item-key="tax"
            class="data-table-full">
            <template #no-data>
                <span>{{ $t('info.noSavedTaxInfo') }} -</span>
                <span
                    class="ml-1 color pointer text-links"
                    @click="addNewTax">{{ $t('addTax') }}</span>
            </template>
            <template #item="{ item: tax }">
                <tr>
                    <td>
                        {{ tax.registration || '' }}
                    </td>
                    <td>
                        {{ tax.postalCode || '' }}
                    </td>
                    <td>
                        {{ tax.country || '' }}
                    </td>
                    <td>
                        <v-menu
                            transition="slide-y-transition"
                            offset-y>
                            <template #activator="{ on, attrs }">
                                <div
                                    v-bind="attrs"
                                    v-on="on">
                                    <v-icon>
                                        mdi-dots-horizontal
                                    </v-icon>
                                </div>
                            </template>
                            <v-list>
                                <v-list-item
                                    @click="editTax(tax)">
                                    <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    class="error--text"
                                    @click="openDelModal(tax)">
                                    <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <TaxModal ref="taxModal" />

        <AlertDeleteModal
            ref="delModal"
            :title="$t('billings.deleteTaxConfirmationTitle')"
            :delete-msg="$t('billings.deleteTaxConfirmationMessage')"
            :item-key-name="''"
            :on-delete="onDeleteTax"
            :on-cancel="onDeleteClose" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import TaxModal from 'pages/settings/taxManagement/TaxModal.vue'
    import TableActions from 'components/alerts/TableActions.vue'
    import AlertDeleteModal from 'components/alerts/AlertDeleteModal.vue'

    export default {
        name: 'TaxTable',
        components: {
            TaxModal,
            TableActions,
            AlertDeleteModal,
        },
        props: {
            taxes: { type: Array, required: true, },
            loading: { type: Boolean, default: false, },
        },
        data () {
            return {
                headers: [
                    { text: this.$t('billings.registration'), value: 'registration',sortable: false,},
                    { text: this.$t('billings.zipCode'), value: 'postalCode', sortable: false, },
                    { text: this.$t('billings.country'), value: 'country', sortable: false, },
                    { text: '', value: 'menu', width: '30px', sortable: false, }
                ],
                selectedItems: [],
                taxForDelete: null,
            }
        },
        computed: {
            ...mapGetters([
                'deleteTaxSuccess'
            ]),
        },
        methods: {
            ...mapActions([
                'fetchTaxes',
                'deleteTax'
            ]),
            openTaxModal ({ mode, tax, }) {
                this.$refs.taxModal.open({ mode, tax, })
            },
            editTax(tax) {
                this.openTaxModal({ mode: 'edit', tax, })
            },
            onDeleteClose() {
                this.taxForDelete = null
                this.$refs.delModal.isDialogOpen = false
            },
            async onDeleteTax() {
                await this.deleteTax(this.taxForDelete?.id)
                this.$refs.delModal.isDialogOpen = false
                if (this.deleteTaxSuccess) {
                    this.$store.commit('showMessage', { text: this.$t('billings.taxDeletedSuccessfully'), type: '#03a128', })
                } else {
                    this.$store.commit('showMessage', { text: this.$t('errors.deleteTax'), type: this.$config.messageType.ERROR, })
                }
                await this.fetchTaxes()
            },
            openDelModal (tax) {
                this.taxForDelete = tax
                this.$refs.delModal.open(this.selectedItems)
            },
            addNewTax () {
                this.openTaxModal({ mode: 'create', })
            },
        },
    }
</script>

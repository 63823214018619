<template>
    <v-card
        outlined
        class="details-card mb-2">
        <v-card-title class="details-title text-body-2 d-flex justify-space-between text-uppercase">
            {{ $t('spending') }}
        </v-card-title>
        <v-card-text>
            <div
                v-if="spendingDataUnAvailable"
                data-auto="spending.not-available">
                <v-row class="pb-5 pl-4 mt-8 text-h6">
                    {{ $t('serviceDetailsTab.spendNotAvailable') }}
                </v-row>
            </div>
            <div
                v-else-if="loading"
                class="d-flex justify-center">
                <v-progress-circular
                    indeterminate
                    size="60" />
            </div>
            <div
                v-else
                data-auto="spending.available">
                <v-row
                    data-auto="spending.total-charges"
                    class="text-caption pb-5">
                    <v-col
                        cols="5"
                        class="px-3 py-0">
                        {{ $t('serviceDetailsTab.currentCharges') }}
                    </v-col>
                    <v-col
                        cols="7"
                        class="px-3 py-0 text-body-1">
                        {{ $t('currency', [totalCost.toFixed(2)]) }}
                    </v-col>
                </v-row>
                <v-row
                    v-if="isServerless"
                    data-auto="spending.scu-hours"
                    class="text-caption pb-5">
                    <v-col
                        cols="5"
                        class="px-3 py-0">
                        {{ $t('serviceDetailsTab.scuHours') }}
                        <ScuHelpTooltip />
                    </v-col>
                    <v-col
                        cols="7"
                        class="px-3 py-0 text-body-1">
                        {{ scuHours }}
                    </v-col>
                </v-row>
                <v-row
                    data-auto="spending.instance-price"
                    class="text-caption">
                    <v-col
                        cols="5"
                        class="px-3 py-0 text-uppercase">
                        {{ service.size }}
                    </v-col>
                    <v-col
                        cols="7"
                        class="px-3 py-0">
                        {{ instanceCostPerHour }}
                    </v-col>
                </v-row>
                <v-row
                    data-auto="spending.storage-price"
                    class="text-caption">
                    <v-col
                        cols="5"
                        class="px-3 py-0">
                        {{ $t('launchService.ssd-storage', [service.storage_volume.size]) }}
                    </v-col>
                    <v-col
                        cols="7"
                        class="px-3 py-0">
                        {{ ssdStorageCostPerHour }}
                    </v-col>
                </v-row>
                <v-row
                    v-if="iopsCostPerHour"
                    data-auto="spending.iops-price"
                    class="text-caption">
                    <v-col
                        cols="5"
                        class="px-3 py-0">
                        {{ $t('compute-iops', [service.storage_volume.iops]) }}
                    </v-col>
                    <v-col
                        cols="7"
                        class="px-3 py-0">
                        {{ iopsCostPerHour }}
                    </v-col>
                </v-row>
                <v-row
                    v-if="scaleFabricCostPerHour"
                    data-auto="spending.infra-price"
                    class="text-caption">
                    <v-col
                        cols="5"
                        class="px-3 py-0">
                        {{ $t('launchService.multiNodeInfra') }}
                    </v-col>
                    <v-col
                        cols="7"
                        class="px-3 py-0">
                        {{ scaleFabricCostPerHour }}
                    </v-col>
                </v-row>
                <v-row
                    v-if="secondaryEndpointCostPerHour"
                    data-auto="spending.endpoint-price"
                    class="text-caption">
                    <v-col
                        cols="5"
                        class="px-3 py-0">
                        {{ $t('dualEndpoints.secondaryEndpoint') }}
                    </v-col>
                    <v-col
                        cols="7"
                        class="px-3 py-0">
                        {{ secondaryEndpointCostPerHour }}
                    </v-col>
                </v-row>
                <v-row
                    v-if="maxScaleCostPerHour"
                    data-auto="spending.maxscale-price"
                    class="text-caption">
                    <v-col
                        cols="5"
                        class="px-3 py-0">
                        {{ maxScaleRedundancyLabel }}
                    </v-col>
                    <v-col
                        cols="7"
                        class="px-3 py-0">
                        {{ maxScaleCostPerHour }}
                    </v-col>
                </v-row>
                <v-row
                    v-if="gp3IopsCostPerHour"
                    data-auto="spending.gp3-iops-price"
                    class="text-caption">
                    <v-col
                        cols="5"
                        class="px-3 py-0">
                        {{ gp3IopsLabel }}
                    </v-col>
                    <v-col
                        cols="7"
                        class="px-3 py-0">
                        {{ gp3IopsCostPerHour }}
                    </v-col>
                </v-row>
                <v-row
                    v-if="gp3ThroughputCostPerHour"
                    data-auto="spending.gp3-throughput-price"
                    class="text-caption">
                    <v-col
                        cols="5"
                        class="px-3 py-0">
                        {{ gp3ThroughputLabel }}
                    </v-col>
                    <v-col
                        cols="7"
                        class="px-3 py-0">
                        {{ gp3ThroughputCostPerHour }}
                    </v-col>
                </v-row>
                <v-row
                    data-auto="spending.total-cost-per-hour"
                    class="text-caption">
                    <v-col
                        cols="5"
                        class="px-3 py-0">
                        {{ $t('total') }}
                    </v-col>
                    <v-col
                        cols="7"
                        class="px-3 py-0">
                        {{ hourlyTotalCost }}
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    import { isServerlessTopology } from 'utils/service'
    import ScuHelpTooltip from 'components/common/ScuHelpTooltip.vue'

    export default {
        name: 'Spending',
        components: {
            ScuHelpTooltip,
        },
        props: {
            totalCost: {
                type: Number,
                default: 0,
            },
            scuHours: {
                type: Number,
                default: 0,
            },
            service: {
                type: Object,
                default: () => { },
            },
            instanceCostPerHour: {
                type: String,
                default: '',
            },
            ssdStorageCostPerHour: {
                type: String,
                default: '',
            },
            iopsCostPerHour: {
                type: String,
                default: '',
            },
            scaleFabricCostPerHour: {
                type: String,
                default: '',
            },
            hourlyTotalCost: {
                type: String,
                default: '',
            },
            spendingDataUnAvailable: {
                type: Boolean,
                default: false,
            },
            maxScaleCostPerHour: {
                type: String,
                default: '',
            },
            maxScaleRedundancyLabel: {
                type: String,
                default: '',
            },
            secondaryEndpointCostPerHour: {
                type: String,
                default: '',
            },
            gp3IopsCostPerHour: {
                type: String,
                default: '',
            },
            gp3IopsLabel: {
                type: String,
                default: '',
            },
            gp3ThroughputCostPerHour: {
                type: String,
                default: '',
            },
            gp3ThroughputLabel: {
                type: String,
                default: '',
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            isServerless() {
                return isServerlessTopology(this.service?.topology)
            },
        },
    }
</script>

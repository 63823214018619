<template>
    <div v-if="treeitems.length">
        <v-text-field
            v-model="search"
            outlined
            dense
            clearable
            hide-details
            label="Filter"
            append-icon="mdi-magnify" />
        <v-treeview
            :items="treeitems"
            :search="search"
            item-key="name"
            open-all
            activatable
            selection-type="independent"
            :active="tree"
            @update:active="selectItem">
            <template #prepend="{ item }">
                <v-icon>
                    {{ item.icon }}
                </v-icon>
            </template>
        </v-treeview>
    </div>
    <div
        v-else
        class="text-center pt-4">
        Schema is empty for this datasource.
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        name: 'CopilotContextMenu',
        data () {
            return {
                search: '',
                tree: [],
            }
        },
        computed: {
            ...mapGetters([
                'selectedDatasource',
                'selectedDatasourceSchema'
            ]),
            treeitems() {
                if (this.selectedDatasource?.schemas?.length) {
                    return this.selectedDatasource.schemas.map(schema => {
                        return {
                            name: schema.name,
                            icon: 'mdi-database',
                            children: schema.tables.map(tableName => ({ name: tableName, icon: 'mdi-table', disabled: true, })),
                        }
                    })
                } else {
                    return []
                }
            },
        },
        watch: {
            selectedDatasourceSchema(schemaName){
                if (schemaName) {
                    this.tree = [schemaName]
                }
            },
        },
        methods: {
            ...mapActions([
                'selectDatasourceSchema'
            ]),
            selectItem(values) {
                if (!values.length) {
                    this.tree = [this.treeitems[0].name]
                }
                if (values[0] && this.selectedDatasourceSchema !== values[0]) {
                    this.selectDatasourceSchema(values[0])
                }
            },
        },
    }
</script>

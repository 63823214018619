<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-col cols="auto pa-0 wrapper-container">
            <SettingHeader
                :header-text="routeName"
                class="mb-10" />
            <div v-if="$route.name === 'Contact Management'">
                <div class="d-flex justify-space-between">
                    <div class="d-flex">
                        <h3 class="font-weight-medium">
                            {{ $t('billings.savedContacts') }}
                        </h3>
                        <v-tooltip top>
                            <template #activator="{ on }">
                                <v-icon
                                    size="21"
                                    class="ml-2"
                                    v-on="on">
                                    mdi-help-circle-outline
                                </v-icon>
                            </template>
                            <span>{{ $t('billings.contactManagementTip') }}</span>
                        </v-tooltip>
                    </div>
                </div>
                <ContactTable
                    :loading="isFetchingContacts"
                    :contacts="contacts" />
            </div>
            <router-view v-else />
        </v-col>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import ContactTable from 'pages/settings/contactManagement/ContactTable.vue'
    import SettingHeader from 'pages/settings/SettingHeader.vue'

    export default {
        name: 'ContactManagement',
        components: {
            ContactTable,
            SettingHeader,
        },
        data() {
            return {
                routeName: this.$route.name ? this.$route.name : this.$route.params.configName,
            }
        },
        computed: {
            ...mapGetters([
                'contacts',
                'contactsError',
                'isFetchingContacts'
            ]),
        },
        watch: {
            $route(to) {
                this.routeName = to.name ? to.name : to.params.configName
            },
        },
        methods: {
            ...mapActions(['fetchContacts']),
        },
        async created() {
            await this.fetchContacts()
        },
    }
</script>

<style lang="scss" scoped>
.instance-sizes-wrapper {
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.outage-box {
    border: 1px solid $quota-border
}

.iops-warn-text {
    color: $warning;
}
</style>
<template>
    <div>
        <!-- Start Stop Popup -->
        <div class="start-stop-popup">
            <base-dialog
                v-model="isStartStopDialogOpen"
                :min-width="800"
                :max-width="800"
                :on-save="changeServiceStatus">
                <template #title>
                    {{ titleForStartStopPopup }}
                </template>
                <template #body>
                    <div class="pt-2">
                        {{ descriptionForStartStopPopup }}
                    </div>
                </template>
                <template #actions="{ cancel, save }">
                    <v-spacer />
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        outlined
                        class="cancel"
                        @click="cancel">
                        <span class="text-none">{{ $t('cancel') }} </span>
                    </v-btn>
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        @click="save">
                        <span class="text-none"> {{ startStopPopupActionButtonText }}</span>
                    </v-btn>
                </template>
            </base-dialog>
        </div>
        <!-- Scale Nodes Popup -->
        <div class="scale-nodes-popup">
            <base-dialog
                v-model="isScaleNodesDialogOpen"
                :min-width="800"
                :max-width="900"
                :on-save="updateNodes"
                :on-close="resetService"
                :on-cancel="resetService">
                <template #title>
                    {{ titleForScaleNodes }}
                </template>
                <template #body>
                    <v-row
                        v-if="!isFetchingDependencies"
                        class="mt-2">
                        <v-col cols="8">
                            <div class="mt-12">
                                <i18n
                                    path="node-scale-message"
                                    tag="p">
                                    <span
                                        place="nodes"
                                        class="font-weight-bold text-lowercase">{{ service.nodes }} {{
                                            $tc('node', service.nodes)
                                        }}</span>
                                </i18n>
                                <Slider
                                    v-if="nodesAvailable.length"
                                    :default-value="existingNodeValue"
                                    :min-value="1"
                                    :step-value="1"
                                    :max-value="nodesAvailable.length"
                                    :data-auto="dataAutoPrefix('content-storageSlider')"
                                    :persist-label="true"
                                    :ticks-labels="nodesTicksLabel"
                                    :show-ticks="true"
                                    class-name="scale-nodes"
                                    @handleChange="handleNodesChange" />
                            </div>
                            <AutoScaleOption
                                class="mt-6"
                                :title="$t('autoScale.nodesInOut')"
                                :is-selected="isHorizontalAutoScalingEnabled"
                                @onChange="toggleAutoScaleOptionForNodeInOut" />
                        </v-col>
                        <v-col cols="4">
                            <v-card class="selection-entity-box pa-0 ma-0 pt-8">
                                <ServiceComputation
                                    :title="$t('scale-node-warning')"
                                    :billing-items="unitCostsForScaleNodeInOut"
                                    :total-estimate="totalCostForHorizontalScaling" />
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-container
                        v-else
                        class="fill-height"
                        fluid>
                        <v-row
                            align="center"
                            justify="center">
                            <v-progress-circular
                                size="70"
                                width="7"
                                color="primary"
                                indeterminate />
                        </v-row>
                    </v-container>
                </template>
                <template #actions="{ cancel, save }">
                    <v-spacer />
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        outlined
                        class="cancel"
                        @click="cancel">
                        <span class="text-none pl-2 pr-2">{{ $t('cancel') }} </span>
                    </v-btn>
                    <v-btn
                        color="primary"
                        depressed
                        :disabled="canScaleNodeHorizontally"
                        rounded
                        @click="save">
                        <span class="text-none pl-2 pr-2"> {{ $tc('applyChanges') }}</span>
                    </v-btn>
                </template>
            </base-dialog>
        </div>
        <!-- Scale Storage Popup -->
        <div class="scale-storage-popup">
            <base-dialog
                v-model="isScaleStorageDialogOpen"
                :min-width="800"
                :max-width="1050"
                :on-save="updateStorageSize"
                :on-close="resetService"
                :on-cancel="resetService">
                <template #title>
                    <p class="font-weight-regular text-h5 ma-0">
                        {{ titleForScaleStorage }}
                    </p>
                </template>
                <template #body>
                    <v-row
                        v-if="!isFetchingDependencies"
                        class="mt-2">
                        <v-col cols="8">
                            <div class="pt-4">
                                <div v-if="isServiceTypeTransactional || isGP3Selected">
                                    <!-- Txn Storage Slider -->
                                    <p class="font-weight-bold text-h6">
                                        {{ $t('ssd-storage-gb') }}
                                    </p>
                                    <p class="text-title font-weight-medium mb-0">
                                        {{ isServiceProviderAWS ? $t('txn-storage-text-aws', {
                                            volumeType:
                                                service.storage_volume.volume_type
                                        }) :
                                            $t('txn-storage-text-gcp')
                                        }}
                                        <a>{{ $t('learn-more') }}</a>
                                    </p>
                                    <p class="text-title font-weight-bold">
                                        {{ $t('txn-downgrade-note') }}
                                    </p>
                                    <div
                                        v-if="isStorageMaxedOut"
                                        data-auto="storage-outage-banner"
                                        class="mt-2 pt-4 mb-2 outage-box">
                                        <div class="pl-8 pr-8">
                                            <p class="mb-2">
                                                <i18n path="foundation-max-storage-outage-warn">
                                                    <span
                                                        place="size"
                                                        class="font-weight-bold">{{
                                                            service.storage_volume.size
                                                        }}</span>
                                                    <span place="tier"> {{ currentTier }}</span>
                                                </i18n>
                                            </p>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <Slider
                                            :min-value="1"
                                            :max-value="storageTicksLabel.length"
                                            :step-value="1"
                                            :data-auto="dataAutoPrefix('content-storageSlider')"
                                            class-name="scale-storage"
                                            :ticks-labels="storageTicksLabel"
                                            @handleChange="handleStorageChange" />
                                    </div>
                                </div>
                                <div
                                    v-if="showIops"
                                    class="mt-4">
                                    <!-- IOPS Slider -->
                                    <p class="font-weight-bold text-h6">
                                        {{ $t('provision-iops-gb') }}
                                    </p>
                                    <v-col
                                        class="pa-0"
                                        cols="12">
                                        <IopsSelector
                                            :default-iops-type="service.storage_volume.volume_type"
                                            :current-value="currentIopsUnits"
                                            :min-value="iopsMinValue"
                                            :max-value="iopsMaxValue"
                                            :step-value="1"
                                            :storage-size="iopsStorageSize"
                                            :parent-data-auto-prefix="dataAutoPrefix"
                                            @handleIopsVolumeChange="handleIopsVolumeChange" />
                                        <span
                                            v-if="showIopsWarning"
                                            class="iops-warn-text font-weight-bold">{{
                                                $t('iops-low-warning')
                                            }}</span>
                                        <div
                                            v-if="isIopsMaxedOut"
                                            data-auto="storage-outage-banner"
                                            class="mt-2 pt-4 mb-2 outage-box">
                                            <div class="pl-8 pr-8 mb-2 ">
                                                {{ $t('foundation-iops-maxed-msg', { tier: currentTier }) }}
                                            </div>
                                        </div>
                                    </v-col>
                                </div>

                                <IopsAndThroughputSelection
                                    v-if="isGP3Selected"
                                    class="mt-n4"
                                    :selected-iops="selectedIops"
                                    :selected-throughput="selectedThroughput"
                                    @on-iops-change="onIopsChange"
                                    @on-throughput-change="onThroughputChange" />
                                <div>
                                    <!-- Power Tier Upgrade Banner -->
                                    <StorageTierUpgradeBanner
                                        v-if="isFoundationTier"
                                        :title="titleForTxnPowerTier" />
                                </div>
                                <AutoScaleOption
                                    v-if="isAutoScaleApplicable"
                                    class="mt-6"
                                    :title="$t('autoScale.storageScale')"
                                    :is-selected="isStorageAutoScalingEnabled"
                                    @onChange="toggleAutoScaleOptionForStorage" />
                            </div>
                        </v-col>
                        <v-col cols="4">
                            <v-card class="selection-entity-box pa-0 ma-0 pt-8">
                                <ServiceComputation
                                    :title="$t('scale-storage-warning')"
                                    :billing-items="unitCostsForScaleStorage"
                                    :total-estimate="totalCostForStorageScaling" />
                            </v-card>
                        </v-col>
                    </v-row>
                    <div
                        v-else
                        class="text-center pt-3">
                        <v-progress-circular
                            size="70"
                            width="7"
                            color="primary"
                            indeterminate />
                    </div>
                </template>
                <template #actions="{ cancel, save }">
                    <v-spacer />
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        outlined
                        class="cancel"
                        @click="cancel">
                        <span class="text-none pl-2 pr-2">{{ $t('cancel') }} </span>
                    </v-btn>
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        :disabled="shouldDisableScaleStorage"
                        @click="save">
                        <span class="text-none pl-2 pr-2"> {{ $tc('applyChanges') }}</span>
                    </v-btn>
                </template>
            </base-dialog>
        </div>
        <!-- Scale Nodes up/down Popup-->
        <div class="scale-nodes-up-down-popup">
            <base-dialog
                v-model="isScaleNodesUpDialogOpen"
                :min-width="800"
                :max-width="1150"
                :on-save="updateNodeInstanceSize"
                :on-close="resetService"
                :on-cancel="resetService">
                <template #title>
                    {{ titleForScaleNodes }}
                </template>
                <template #body>
                    <v-row
                        v-if="!isFetchingDependencies"
                        class="mt-2">
                        <v-col cols="8">
                            <div class="instance-sizes-wrapper text-no-wrap">
                                <i18n
                                    path="current-node-size"
                                    tag="p">
                                    <strong
                                        place="size"
                                        class="text-capitalize">{{ service.size }}</strong>
                                </i18n>
                                <ServerInstanceList
                                    class="ma-3"
                                    :instance-list="verticalScaleInstanceSizes"
                                    :pre-selected-instance-id="preSelectedInstanceId"
                                    :parent-data-auto-prefix="dataAutoPrefix"
                                    :instance-cost="instanceCosts"
                                    :selected-arch="service.architecture"
                                    @instanceSelected="onInstanceSelect" />
                            </div>
                            <InstanceTierUpgradeBanner
                                v-if="isFoundationTier"
                                :parent-data-auto-prefix="dataAutoPrefix" />
                            <AutoScaleOption
                                class="mt-6"
                                :title="$t('autoScale.nodeUpDown')"
                                :is-selected="isVerticalAutoScalingEnabled"
                                @onChange="toggleAutoScaleOptionForNodeUpDown" />
                        </v-col>
                        <v-col cols="4">
                            <v-card class="selection-entity-box pa-0 ma-0 pt-8">
                                <ServiceComputation
                                    :title="$t('scale-node-warning')"
                                    :billing-items="unitCostsForScaleNodesUpDown"
                                    :total-estimate="totalCostForVerticalScaling" />
                            </v-card>
                        </v-col>
                    </v-row>
                    <div
                        v-else
                        class="text-center pt-3">
                        <v-progress-circular
                            size="70"
                            width="7"
                            color="primary"
                            indeterminate />
                    </div>
                </template>
                <template #actions="{ cancel, save }">
                    <v-spacer />
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        outlined
                        class="cancel pl-2 pr-2"
                        @click="cancel">
                        <span class="text-none">{{ $t('cancel') }} </span>
                    </v-btn>
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        :disabled="canScaleNodeVertically"
                        @click="save">
                        <span class="text-none pl-2 pr-2"> {{ $tc('applyChanges') }}</span>
                    </v-btn>
                </template>
            </base-dialog>
        </div>
        <!-- Autonomous Popup-->
        <div class="autonomous-popup">
            <base-dialog
                v-model="isAutonomousDialogOpen"
                :min-width="600"
                :max-width="950"
                :on-save="applyAutoScale"
                :on-close="resetService"
                :on-cancel="resetService">
                <template #title>
                    {{ $t('autonomousSettings') }}
                </template>
                <template #body>
                    <!-- TODO:: Conditional scaling based on different service types -->
                    <div
                        v-if="!isFetchingDependencies"
                        class="ml-3 mt-8">
                        <NodeAutoScale
                            :can-scale-horizontal="canScaleHorizontal"
                            :horizontal-scale-range="availableHorizontalScaleRange"
                            :vertical-scale-range="autoVerticalSizes"
                            :title="$t('autoScaleNodes')"
                            :default-scale-option="preselectedAutoScaleTypeForNodes"
                            :default-scale-value="preselectedAutoScaleValueForNodes"
                            @on-toggle-auto-scaling="onAutoNodeToggle"
                            @on-auto-scale-type="onAutoScaleTypeForNodeSelected"
                            @on-auto-scale-value="onAutoScaleValueForNodeSelected" />

                        <StorageAutoScale
                            v-if="isStorageAutoScalingAllowed"
                            :title="$t('autoScaleStorage')"
                            :storage-range="availableStorageSizeRange"
                            :should-disable="isMaxStorageSelected"
                            :default-storage-value="preselectedAutoScaleValueForStorage"
                            @on-toggle-auto-scaling="onAutoStorageToggle"
                            @on-auto-storage-value="onAutoStorageScaleSelected" />

                        <div v-if="$unleash.isFeatureEnabled('enable-portal-auto-tune')">
                            <v-row class="mt-2 text-h6 font-weight-bold">
                                {{ $t('autoScaleOptions') }}
                            </v-row>
                            <v-row class="mt-4">
                                <v-col>
                                    <v-checkbox
                                        class="pt-0 mt-0"
                                        :label="$t('enableAutoTuning')">
                                        <template #append>
                                            <v-icon size="20">
                                                $vuetify.icons.questionCircle
                                            </v-icon>
                                        </template>
                                    </v-checkbox>
                                </v-col>
                                <v-col />
                                <v-col />
                            </v-row>
                        </div>
                    </div>
                    <v-container
                        v-else
                        class="fill-height"
                        fluid>
                        <v-row
                            align="center"
                            justify="center">
                            <v-progress-circular
                                size="70"
                                width="7"
                                color="primary"
                                indeterminate />
                        </v-row>
                    </v-container>
                </template>
                <template #actions="{ cancel, save }">
                    <v-spacer />
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        outlined
                        class="cancel pl-2 pr-2"
                        @click="cancel">
                        <span class="text-none">{{ $t('cancel') }} </span>
                    </v-btn>
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        :disabled="shouldDisableAutoScaleApply"
                        @click="save">
                        <span class="text-none pl-2 pr-2"> {{ $tc('applyChanges') }}</span>
                    </v-btn>
                </template>
            </base-dialog>
        </div>
        <PrivateConnectModal />
        <ScalingOptionsModal />
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
    import i18n from 'plugins/i18n'
    import Slider from 'components/Slider'
    import InstanceTierUpgradeBanner from 'components/common/InstanceTierUpgradeBanner.vue'
    import ServerInstanceList from 'components/common/ServerInstanceList.vue'
    import ServiceComputation from 'components/common/ServiceComputation.vue'
    import { capitalize } from 'helpers'
    import StorageTierUpgradeBanner from 'components/common/StorageTierUpgradeBanner.vue'
    import IopsSelector from 'components/launchService/IopsSelector.vue'
    import AutoScaleOption from 'components/service/AutoScaleOption.vue'
    import StorageAutoScale from 'components/autonomous/StorageAutoScale.vue'
    import NodeAutoScale from 'components/autonomous/NodeAutoScale.vue'
    import PrivateConnectModal from 'components/service/PrivateConnectModal.vue'
    import ScalingOptionsModal from 'components/service/ScalingOptionsModal.vue'
    import iopsComputation from 'mixins/iopsComputation.js'
    import autonomousModal from 'mixins/autonomousModal'
    import IopsAndThroughputSelection from 'components/launchService/IopsAndThroughputSelection'
    import {
        getTotalPricingForInstances,
        getTotalPricingForStorage,
        getTotalPricingForIOPS,
        getTotalPricingForScaleFabric,
        getFilteredInstanceCosts,
        getTotalPricingForGP3IOPS,
        getTotalPricingForGP3Throughput
    } from 'utils/billing'
    import { mapToAutoScalePolicyObjects } from 'mappers/autoScaling'
    import {
        isStandAloneTopology,
        isColumnstore,
        isSingleNodeTopology,
        isSingleNodeAnalytics,
        isXpand,
        getFilteredInstanceSizes
    } from 'utils/service'

    export default {
        name: 'ManageServicePopup',
        components: {
            Slider,
            InstanceTierUpgradeBanner,
            ServerInstanceList,
            ServiceComputation,
            StorageTierUpgradeBanner,
            IopsSelector,
            AutoScaleOption,
            StorageAutoScale,
            NodeAutoScale,
            PrivateConnectModal,
            ScalingOptionsModal,
            IopsAndThroughputSelection,
        },
        mixins: [iopsComputation, autonomousModal],
        data() {
            return {
                currentNodes: 0,
                currentStorage: 0,
                currentInstance: {},
                updatedIopsValue: this.currentIopsUnits,
                userEnabledAutoScale: false,
                userEnabledVerticalScale: false,
                userEnabledStorageScale: false,
                iopsMinValue: 1,
                nodeAutoScaleOptions: [],
                storageAutoScaleOptions: [],
                iopsMaxValue: this.$config.IOPS_MAX,
                autoScaleNodesValue: undefined,
                autoScaleStorageValue: undefined,
                isStorageAutoOptionChecked: undefined,
                isNodeAutoOptionChecked: undefined,
                selectedIops: 0,
                selectedThroughput: 0,
            }
        },
        computed: {
            ...mapState(['manageServices']),
            ...mapGetters([
                'stateChangeSuccess',
                'instanceSizes',
                'currentTier',
                'storageSizes',
                'nodeSizes',
                'iopsValues',
                'isStorageSizeFetching',
                'instanceCosts',
                'storageCosts',
                'iopsCosts',
                'isInstanceSizeFetching',
                'autoVerticalSizes',
                'servicePolicies',
                'isFetchingDependencies',
                'gp3IopsCosts',
                'volumeTypes',
                'throughputCosts',
                'gp3StorageCosts'
            ]),
            service() {
                return this.getServiceById(this.manageServices.serviceId)
            },
            isVerticalAutoScalingEnabled() {
                if (!this.preSelectedAutoScaleNodePolicy) return false
                return Boolean(this.preSelectedAutoScaleNodePolicy.group === this.$config.VERTICAL_SCALE_GROUP)
            },
            isHorizontalAutoScalingEnabled() {
                if (!this.preSelectedAutoScaleNodePolicy) return false
                return Boolean(this.preSelectedAutoScaleNodePolicy.group === this.$config.HORIZONTAL_SCALE_GROUP)
            },
            isStorageAutoScalingEnabled() {
                return Boolean(this.preselectedAutoScaleValueForStorage)
            },
            serviceId() {
                return this.service.id
            },
            serviceStatus() {
                return this.service.status
            },
            isServiceReady() {
                return this.service.status === this.$config.serviceStatus.READY
            },
            titleForStartStopPopup() {
                return this.$t('services.startStopServiceTitle', { action: this.isServiceReady ? this.$t('stop') : this.$t('start'), })
            },
            descriptionForStartStopPopup() {
                return this.isServiceReady ? this.$t('services.stopServiceDescription') : this.$t('services.startServiceDescription')
            },
            startStopPopupActionButtonText() {
                return this.$t('services.startStopThisService', { action: this.isServiceReady ? this.$t('stop') : this.$t('start'), })
            },
            isStartStopDialogOpen: {
                get() {
                    return this.manageServices.isStartStopOpen
                },
                set(value) {
                    this.setStartStopDialog(value)
                },
            },
            isScaleNodesDialogOpen: {
                get() {
                    return this.manageServices.isScaleNodesOpen
                },
                set(value) {
                    this.setScaleNodesDialog(value)
                },
            },
            isScaleStorageDialogOpen: {
                get() {
                    return this.manageServices.isScaleStorageOpen
                },
                set(value) {
                    this.setScaleStorageDialog(value)
                },
            },
            titleForScaleNodes() {
                return `${this.$t('skysql')}: ${this.$t('scaleNodes')}`
            },
            existingNodeValue() {
                return this.nodesTicksLabel.indexOf(this.service.nodes) + 1
            },
            nodesTicksLabel() {
                return this.nodesAvailable
            },
            nodesAvailable() {
                // Specially for xpand, once a user has scaled post 1 node in Xpand
                // he should not be able to scale less than 3
                // as per https://jira.mariadb.org/browse/DBAAS-11230
                if (isXpand(this.service.topology) && this.service.nodes > 1) {
                    const clonedNodeSizes = this.$lodash.clone(this.nodeSizes)
                    clonedNodeSizes.shift()
                    return clonedNodeSizes
                }
                return this.nodeSizes
            },
            titleForScaleStorage() {
                return `${this.$t('skysql')}: ${this.$t('scaleStorage')}`
            },
            storageTicksLabel() {
                let storageLabelArray = []
                if (this.storageSizes.length) {
                    storageLabelArray = this.storageSizes.filter(size => size >= this.service.storage_volume.size)
                }

                return storageLabelArray
            },
            isScaleNodesUpDialogOpen: {
                get() {
                    return this.manageServices.isScaleNodesUpOpen
                },
                set(value) {
                    this.setScaleNodeUpDialog(value)
                },
            },
            preSelectedInstanceId() {
                if (this.verticalScaleInstanceSizes.length) {
                    const matchedInstance = this.verticalScaleInstanceSizes.find(instanceSize =>
                        instanceSize.name === this.service.size
                    )
                    return matchedInstance ? matchedInstance.id : ''
                }
                return ''
            },
            canScaleNodeVertically() {
                return this.preSelectedInstanceId === this.getCurrentInstanceId() && this.isVerticalAutoScalingEnabled === this.userEnabledVerticalScale
            },
            titleForTxnPowerTier() {
                if (this.isServiceProviderAWS && this.isServiceTypeTransactional) {
                    return i18n.t('need-storage-iops')
                }

                if (this.isServiceProviderAWS) {
                    return i18n.t('need-more-iops')
                }

                return i18n.t('need-more-storage')
            },
            isServiceProviderAWS() {
                return this.service.provider === this.$config.cloudProvider.AWS
            },
            isStorageMaxedOut() {
                return this.service.storage_volume.size === this.maxTxnStorage
            },
            isIopsMaxedOut() {
                return this.currentIopsUnits >= this.iopsMaxValue
            },
            isServiceTypeTransactional() {
                return this.service.service_type === this.$config.TRANSACTIONAL
            },
            canScaleNodeHorizontally() {
                if (this.isFetchingDependencies) return true
                return this.service.nodes === this.currentNodes && this.userEnabledAutoScale === this.isHorizontalAutoScalingEnabled
            },
            unitCostsForScaleNodeInOut() {
                if (!this.instanceCosts || !this.instanceCosts.length) return []
                const { size, storage_volume: { size: storageSize, iops, }, } = this.service
                const iopsPerGB = iops / storageSize

                return this.getBillingDetails(size, storageSize, this.currentNodes, iopsPerGB, false, false)
            },
            iopsStorageSize() {
                return this.isServiceTypeTransactional ? this.currentStorage : this.$config.ANALYTICAL_DEFAULT_STORAGE
            },
            currentIopsUnits() {
                const { size, iops, } = this.service.storage_volume
                return iops / size
            },
            showIopsWarning() {
                return this.currentIopsUnits > this.updatedIopsValue
            },
            shouldDisableScaleStorage() {
                if (this.isGP3Selected) {
                    const { iops, throughput, size, } = this.service.storage_volume
                    return iops === this.selectedIops && throughput === this.selectedThroughput && size === this.currentStorage && this.userEnabledStorageScale === this.isStorageAutoScalingEnabled
                }
                const { size, iops, } = this.service.storage_volume
                if (iops) {
                    return size === this.currentStorage && iops / size === this.updatedIopsValue && this.userEnabledStorageScale === this.isStorageAutoScalingEnabled
                }
                return size === this.currentStorage && this.userEnabledStorageScale === this.isStorageAutoScalingEnabled
            },
            isFoundationTier() {
                return this.currentTier === this.$config.FOUNDATION_TIER
            },
            unitCostsForScaleStorage() {
                if (!this.instanceCosts || !this.instanceCosts.length) return []
                let currentInstanceSize = this.currentInstance && this.currentInstance.name ? this.currentInstance.name : this.service.size

                const { nodes, } = this.service

                return this.getBillingDetails(currentInstanceSize, this.iopsStorageSize, nodes, this.updatedIopsValue, true, false)
            },
            isIOPSApplicable() {
                return this.service.storage_volume.iops
            },
            isTxnStorageApplicable() {
                return this.isServiceTypeTransactional
            },
            unitCostsForScaleNodesUpDown() {
                if (!this.instanceCosts || !this.instanceCosts.length) return []
                let currentInstanceSize = this.currentInstance && this.currentInstance.name ? this.currentInstance.name : this.service.size

                const { storage_volume: { size: storageSize, iops, }, nodes, } = this.service
                const iopsPerGB = iops / storageSize

                return this.getBillingDetails(currentInstanceSize, storageSize, nodes, iopsPerGB, false, true)
            },
            isAutonomousDialogOpen: {
                get() {
                    return this.manageServices.isAutonomousOpen
                },
                set(value) {
                    this.setAutonomousDialog(value)
                },
            },
            isAutoScaleApplicable() {
                return this.isServiceTypeTransactional
            },
            isStorageAutoScalingAllowed() {
                return this.isServiceTypeTransactional
            },
            maxTxnStorage() {
                return this.storageSizes.at(-1)
            },
            canScaleHorizontal() {
                const { topology, } = this.service
                return !(isColumnstore(topology) || isStandAloneTopology(topology))
            },
            availableHorizontalScaleRange() {
                return this.canScaleHorizontal ? this.nodesTicksLabel.filter(val => val > this.service.nodes) : []
            },
            availableStorageSizeRange() {
                return this.storageSizes.filter((size) => this.service.storage_volume.size < size)
            },
            isMaxStorageSelected() {
                return this.service.storage_volume.size === this.storageSizes.at(-1)
            },
            isSingleNodeAnalytics() {
                const { topology, nodes, } = this.service
                return isSingleNodeAnalytics(topology, nodes)
            },
            totalCostForHorizontalScaling() {
                if (!this.instanceCosts || !this.instanceCosts.length) return {}
                const { size, storage_volume: { size: storageSize, iops, }, } = this.service
                let iopsPerGB = 0
                if (this.isIOPSApplicable) {
                    iopsPerGB = iops / storageSize
                }
                return {
                    costPerHour: this.getTotalEstimate(size, storageSize, this.currentNodes, iopsPerGB),
                    costPerMonth: this.getTotalEstimate(size, storageSize, this.currentNodes, iopsPerGB, 'month'),
                }
            },
            totalCostForVerticalScaling() {
                if (!this.instanceCosts || !this.instanceCosts.length) return {}
                let currentInstanceSize = this.currentInstance && this.currentInstance.name ? this.currentInstance.name : this.service.size

                const { storage_volume: { size: storageSize, iops, }, nodes, } = this.service
                let iopsPerGB = 0
                if (this.isIOPSApplicable) {
                    iopsPerGB = iops / storageSize
                }
                return {
                    costPerHour: this.getTotalEstimate(currentInstanceSize, storageSize, nodes, iopsPerGB),
                    costPerMonth: this.getTotalEstimate(currentInstanceSize, storageSize, nodes, iopsPerGB, 'month'),
                }
            },
            totalCostForStorageScaling() {
                if (!this.instanceCosts || !this.instanceCosts.length) return {}
                let currentInstanceSize = this.currentInstance && this.currentInstance.name ? this.currentInstance.name : this.service.size

                const { nodes, } = this.service
                return {
                    costPerHour: this.getTotalEstimate(currentInstanceSize, this.iopsStorageSize, nodes, this.updatedIopsValue),
                    costPerMonth: this.getTotalEstimate(currentInstanceSize, this.iopsStorageSize, nodes, this.updatedIopsValue, 'month'),
                }
            },
            preSelectedAutoScaleNodePolicy() {
                const { VERTICAL_SCALE_GROUP, HORIZONTAL_SCALE_GROUP, } = this.$config
                return this.currentServicePolicies.find(policy => [VERTICAL_SCALE_GROUP, HORIZONTAL_SCALE_GROUP].includes(policy.group))
            },
            preselectedAutoScaleTypeForNodes() {
                const { VERTICAL_SCALE_GROUP, AUTO_VERTICAL_SCALE, AUTO_HORIZONTAL_SCALE, } = this.$config
                if (!this.preSelectedAutoScaleNodePolicy) return null
                return this.preSelectedAutoScaleNodePolicy.group === VERTICAL_SCALE_GROUP ? AUTO_VERTICAL_SCALE : AUTO_HORIZONTAL_SCALE
            },
            preselectedAutoScaleValueForNodes() {
                if (!this.preSelectedAutoScaleNodePolicy) return null
                const { VERTICAL_SCALE_GROUP, } = this.$config
                const { params, } = this.preSelectedAutoScaleNodePolicy
                if (this.preSelectedAutoScaleNodePolicy.group === VERTICAL_SCALE_GROUP) {
                    return params.max_node_size
                }
                return params.max_nodes
            },
            preselectedAutoScaleStoragePolicy() {
                const { STORAGE_SCALE_GROUP, } = this.$config
                return this.currentServicePolicies.find(policy => STORAGE_SCALE_GROUP === policy.group)
            },
            preselectedAutoScaleValueForStorage() {
                if (!this.preselectedAutoScaleStoragePolicy) return null
                return this.preselectedAutoScaleStoragePolicy.params.max_storage_size_gbs
            },
            currentServicePolicies() {
                return this.servicePolicies.filter(policy => policy.service_id === this.service.id)
            },
            showIops() {
                return this.isServiceProviderAWS && this.service.storage_volume.volume_type === this.$config.iops.io1
            },
            shouldDisableAutoScaleApply() {
                let shouldDisable = false
                //Initial condition when nothing is selected by user
                // Basically the autonomous form selection is not dirty
                // In such situation keep the button disabled
                if (this.autoScaleNodesValue === undefined && this.autoScaleStorageValue === undefined) return true

                if (this.preselectedAutoScaleValueForNodes) {
                    // In case the user already has a node scale policy applied
                    // check if the new value user opting is different
                    // from initial selection
                    shouldDisable = this.preselectedAutoScaleValueForNodes === this.autoScaleNodesValue
                } else {
                    shouldDisable = !this.autoScaleNodesValue
                }

                // If nodes values changes, enable apply
                if (!shouldDisable) return shouldDisable

                if (this.preselectedAutoScaleValueForStorage) {
                    // In case the user already has a storage scale policy applied
                    // check if the new value user opting is different
                    // from initial selection
                    shouldDisable = this.preselectedAutoScaleValueForStorage === this.autoScaleStorageValue
                } else {
                    shouldDisable = !this.autoScaleStorageValue
                }

                return shouldDisable
            },
            verticalScaleInstanceSizes() {
                if (this.instanceSizes.length) {
                    return getFilteredInstanceSizes(this.instanceSizes, {
                        provider: this.service.provider,
                        serviceType: this.service.service_type,
                        topology: this.service.topology,
                        arch: this.service.architecture,
                    })
                }
                return []
            },
            isGP3Selected() {
                return this.service.storage_volume.volume_type == this.$config.iops.gp3
            },
            gp3Iops() {
                let type = this.volumeTypes.find(type => type.name == this.$config.iops.gp3)
                return type ? type.iops[0] : {}
            },
            areIopsCostApplicable() {
                return this.selectedIops > this.gp3Iops.total_iops_range_min
            },
            areThroughputCostApplicable() {
                return this.selectedThroughput > this.gp3Iops.throughput_range_min
            },
            volumeStorageCosts() {
                if (!this.storageCosts) {
                    return {}
                }
                const volumeCosts = this.storageCosts.find(sc => sc.name === this.service?.storage_volume?.volume_type)
                return volumeCosts || this.storageCosts[0]
            },
        },
        watch: {
            manageServices: {
                deep: true,
                async handler() {
                    if (this.manageServices.isScaleStorageOpen && this.isGP3Selected) {
                        const { iops, throughput, } = this.service.storage_volume
                        await this.fetchVolumeTypes(this.service.provider)
                        this.selectedIops = iops
                        this.selectedThroughput = throughput
                    }
                },
            },
        },
        methods: {
            ...mapMutations([
                'setStartStopDialog',
                'setScaleNodesDialog',
                'setScaleStorageDialog',
                'setScaleNodeUpDialog',
                'setAutonomousDialog'
            ]),
            ...mapActions([
                'startStopService',
                'scaleNodes',
                'scaleStorage',
                'scaleNodesUpDown',
                'scaleIOPS',
                'createAutoScalePolicies',
                'deletePolicy',
                'setPrivateConnect',
                'fetchVolumeTypes'
            ]),
            async changeServiceStatus() {
                let desiredServiceState = !(this.isServiceReady)
                await this.startStopService({ serviceId: this.serviceId, serviceState: desiredServiceState, })
                if (this.stateChangeSuccess) {
                    // TODO:: cleanup this as tech-debt as live statuses are coming from DPS
                    // UI hack to handle the part to temporarily
                    // show stopped icon on service list as API not updating
                    // the ready icon for a stopped service
                    if (this.isServiceReady) {
                        this.$emit('onStoppedServer', this.serviceId)
                    }
                }
                this.setStartStopDialog(false)
            },
            handleNodesChange(nodes) {
                this.currentNodes = nodes
            },
            async updateNodes() {
                // Guards added to not make API request
                // if no changes made by user
                if (this.currentNodes !== this.service.nodes) {
                    await this.scaleNodes({ serviceId: this.serviceId, nodes: this.currentNodes, })
                }
                this.setScaleNodesDialog(false)
                if (this.userEnabledAutoScale && !this.isHorizontalAutoScalingEnabled) {
                    this.fetchAutoScaleDependencies()
                    this.openAutonomousDialog({ popupState: true, serviceId: this.service.id, })
                }
            },
            dataAutoPrefix(string) {
                return 'manage-menu' + string
            },
            handleStorageChange(number) {
                this.currentStorage = number
                this.computeIopsRange(this.currentStorage)
            },
            async updateStorageSize() {
                const { size, iops, } = this.service.storage_volume

                let storageUpdates = []
                if (this.isTxnStorageApplicable && size !== this.currentStorage && !this.isStorageMaxedOut) {
                    storageUpdates.push({ size: this.currentStorage, })
                }

                if (this.isIOPSApplicable && !this.isGP3Selected) {
                    const iopsTotal = this.updatedIopsValue * this.iopsStorageSize
                    if (iops !== iopsTotal) {
                        storageUpdates.push({ iops: iopsTotal, })
                    }
                }

                if (this.isGP3Selected) {
                    storageUpdates.push({ iops: this.selectedIops, })
                    storageUpdates.push({ throughput: this.selectedThroughput, })
                }

                if (storageUpdates.length) {
                    const storageValues = storageUpdates.
                        reduce((result, current) => Object.assign(result, current),
                               {}
                        )

                    await this.scaleStorage({
                        serviceId: this.serviceId,
                        storageValues,
                    })
                }

                this.setScaleStorageDialog(false)

                if (this.userEnabledStorageScale && !this.isStorageAutoScalingEnabled) {
                    this.fetchAutoScaleDependencies()
                    this.openAutonomousDialog({
                        popupState: true,
                        serviceId: this.service.id,
                    })
                }
            },
            getCurrentInstanceId() {
                if (!this.currentInstance.id) {
                    this.currentInstance.id = this.preSelectedInstanceId
                }
                return this.currentInstance.id
            },
            onInstanceSelect(instance) {
                this.currentInstance = instance
            },
            async updateNodeInstanceSize() {
                // Guards added to not make API request
                // if no changes made by user
                if (this.preSelectedInstanceId !== this.getCurrentInstanceId()) {
                    this.scaleNodesUpDown({ serviceId: this.serviceId, size: this.currentInstance.name, })
                }
                this.setScaleNodeUpDialog(false)
                if (this.userEnabledVerticalScale && !this.isVerticalAutoScalingEnabled) {
                    this.fetchAutoScaleDependencies()
                    this.openAutonomousDialog({ popupState: true, serviceId: this.service.id, })
                }
            },
            handleIopsVolumeChange(iops) {
                this.updatedIopsValue = iops
                if (isColumnstore(this.service.topology)) {
                    // For analytical service use storage 100
                    // to get the IOPS range
                    this.computeIopsRange(this.iopsStorageSize)
                }
            },
            resetService() {
                // Once a service modal is closed
                // reset up all the instance variable
                // to be used by another service
                this.currentNodes = 0
                this.currentStorage = 0
                this.currentInstance = {}
                this.updatedIopsValue = this.currentIopsUnits
                this.userEnabledAutoScale = false
                this.userEnabledVerticalScale = false
                this.userEnabledStorageScale = false
                this.iopsMinValue = 1
                this.nodeAutoScaleOptions = []
                this.storageAutoScaleOptions = []
                this.autoScaleStorageValue = undefined
                this.autoScaleNodesValue = undefined
                this.isStorageAutoOptionChecked = undefined
                this.isNodeAutoOptionChecked = undefined
            },
            async applyAutoScale() {
                const actions = this.setupAutoScaling()
                let promises = []
                if (actions.length) {
                    // eslint-disable-next-line camelcase
                    let scalingPolicies = { service_name: this.service.name, service_id: this.service.id, actions, }
                    promises.push(this.createAutoScalePolicies(scalingPolicies))
                }
                const deleteActionIds = this.getDeleteActionIds(actions)
                if (deleteActionIds.length) {
                    deleteActionIds.forEach(id => {
                        promises.push(this.deletePolicy(id))
                    })
                }
                await Promise.all(promises)
                await this.fetchPoliciesForService()
            },
            toggleAutoScaleOptionForNodeInOut(isAutoEnable) {
                this.userEnabledAutoScale = isAutoEnable
            },
            toggleAutoScaleOptionForNodeUpDown(isAutoEnable) {
                this.userEnabledVerticalScale = isAutoEnable
            },
            toggleAutoScaleOptionForStorage(isAutoEnable) {
                this.userEnabledStorageScale = isAutoEnable
            },
            getBillingDetails(instanceSize, storageSize, nodes, iops, isNodeStatic = false, isStorageStatic = false) {
                if (!this.instanceCosts || !this.instanceCosts.length) return []
                const { topology, } = this.service

                let computeValues = [
                    { ...this.getInstancePricingObject(topology, instanceSize, nodes), isStatic: isNodeStatic, }
                ]

                if (this.isTxnStorageApplicable && this.volumeStorageCosts) {
                    computeValues.push({ ...this.getStoragePricingObject(topology, nodes, storageSize), isStatic: isStorageStatic, })
                }

                if (this.isIOPSApplicable && !this.isGP3Selected) {
                    computeValues.push(
                        { ...this.getIOPSPricingObject(topology, nodes, storageSize, iops), isStatic: isStorageStatic, }
                    )
                }

                if (this.isGP3Selected) {
                    if (this.areIopsCostApplicable) computeValues.push({ ...this.getGP3IOPSPricingObject(topology, this.selectedIops, nodes), })
                    if (this.areThroughputCostApplicable) computeValues.push({ ...this.getGP3ThroughputPricingObject(topology, this.selectedThroughput, nodes), })
                }

                if (!isStandAloneTopology(topology) && !this.isSingleNodeAnalytics) {
                    computeValues.push({ ...this.getScaleFabricPricingObject(topology, instanceSize, nodes), isStatic: isNodeStatic, })
                }

                return computeValues
            },
            getInstancePricingObject(topology, instanceSize, nodes) {
                return {
                    name: `${capitalize(instanceSize)}(x${nodes})`,
                    value: getTotalPricingForInstances(topology, instanceSize, nodes, this.filterInstanceCosts()),
                }
            },
            getStoragePricingObject(topology, nodes, storageSize) {
                let storageCosts = this.volumeStorageCosts
                if (this.isGP3Selected) storageCosts = this.gp3StorageCosts
                return {
                    name: i18n.t('launchService.ssd-storage', [storageSize]),
                    value: getTotalPricingForStorage(topology, storageSize, nodes, storageCosts),
                }
            },
            getIOPSPricingObject(topology, nodes, storageSize, iops) {
                return {
                    name: i18n.t('compute-iops', [iops * storageSize]),
                    value: getTotalPricingForIOPS(topology, storageSize, nodes, iops, this.iopsCosts),
                }
            },
            getScaleFabricPricingObject(topology, instanceSize, nodes) {
                return {
                    name: i18n.t('launchService.multiNodeInfra'),
                    value: getTotalPricingForScaleFabric(topology, instanceSize, nodes, this.filterInstanceCosts()),
                }
            },
            getGP3IOPSPricingObject(topology, iops, nodes) {
                const { total_iops_range_min: iopsMin, } = this.gp3Iops
                return {
                    name: `${iops} ${i18n.t('launchService.provisionedIOPs')}`,
                    value: getTotalPricingForGP3IOPS(topology, iops - iopsMin, nodes, this.gp3IopsCosts),
                }
            },
            getGP3ThroughputPricingObject(topology, throughput, nodes) {
                const { throughput_range_min: throughputMin, } = this.gp3Iops
                return {
                    name: `${throughput} ${i18n.t('mbPerSec')} ${i18n.t('launchService.storageThroughput')}`,
                    value: getTotalPricingForGP3Throughput(topology, throughput - throughputMin, nodes, this.throughputCosts), // TODO: replace with throughput cost
                }
            },
            getTotalEstimate(instanceSize, storageSize, nodes, iops, duration = 'hour') {
                const { topology, } = this.service
                let cost = 0
                let storageCosts = this.volumeStorageCosts
                if (this.isGP3Selected) storageCosts = this.gp3StorageCosts
                cost += getTotalPricingForInstances(topology, instanceSize, nodes, this.filterInstanceCosts(), duration)
                cost += getTotalPricingForStorage(topology, storageSize, nodes, storageCosts, duration)
                cost += this.isIOPSApplicable && !this.isGP3Selected ? getTotalPricingForIOPS(topology, storageSize, nodes, iops, this.iopsCosts, duration) : 0
                cost += getTotalPricingForScaleFabric(topology, instanceSize, nodes, this.filterInstanceCosts(), duration)
                if (this.isGP3Selected) {
                    const { total_iops_range_min: iopsMin, throughput_range_min: throughputMin, } = this.gp3Iops
                    cost += this.areIopsCostApplicable ? getTotalPricingForGP3IOPS(topology, this.selectedIops - iopsMin, nodes, this.gp3IopsCosts, duration) : 0
                    cost += this.areThroughputCostApplicable ? getTotalPricingForGP3Throughput(topology, this.selectedThroughput - throughputMin, nodes, this.throughputCosts, duration) : 0
                }
                // TODO: this function can be simplified if we use below commented code directly
                // duration should be an additional parameter passed with 'hour' being default
                // this.getBillingDetails(instanceSize, storageSize, nodes, iops).forEach(bill => cost += bill.value)
                return cost
            },
            fetchAutoScaleDependencies() {
                this.setServiceDepsFetching(true)
                // eslint-disable-next-line camelcase
                const { provider, service_type, architecture, size, topology, id, nodes, } = this.service
                let dependencies = []
                if (!isSingleNodeTopology(topology, nodes)) dependencies.push(this.fetchNodeSizes(topology))
                dependencies.push(this.fetchPoliciesForService(id))

                if (this.canScaleStorage) dependencies.push(this.fetchStorageSizes(topology))

                dependencies.push(this.fetchVerticalScaleNodeSizes({
                    // eslint-disable-next-line camelcase
                    service_type,
                    provider,
                    ...(provider === this.$config.cloudProvider.AWS && { architecture, }),
                    // eslint-disable-next-line camelcase
                    min_node_size: size,
                }))

                Promise.all(dependencies).then(() => {
                    this.setServiceDepsFetching(false)
                })
            },
            onAutoScaleTypeForNodeSelected(scaleType) {
                // We support single scale option, so pop out existing
                if (this.nodeAutoScaleOptions.length) this.nodeAutoScaleOptions.pop()
                this.nodeAutoScaleOptions.push({ type: scaleType, })
                this.autoScaleNodesValue = null
            },
            onAutoScaleValueForNodeSelected(scaleValue) {
                //This is when options are preselected
                if (!this.nodeAutoScaleOptions.length) {
                    this.nodeAutoScaleOptions.push({ type: this.preselectedAutoScaleTypeForNodes, })
                }
                this.nodeAutoScaleOptions[0].maxValue = scaleValue
                this.autoScaleNodesValue = scaleValue
            },
            onAutoStorageScaleSelected(storageValue) {
                if (this.storageAutoScaleOptions.length) this.storageAutoScaleOptions.pop()
                this.storageAutoScaleOptions.push({
                    type: this.$config.AUTO_STORAGE_SCALE,
                    value: storageValue,
                })
                this.autoScaleStorageValue = storageValue
            },
            onAutoStorageToggle(isChecked) {
                this.isStorageAutoOptionChecked = isChecked
                if (!isChecked) {
                    this.storageAutoScaleOptions.pop()
                    this.autoScaleStorageValue = null
                }
            },
            onAutoNodeToggle(isChecked) {
                this.isNodeAutoOptionChecked = isChecked
                if (!isChecked) {
                    this.nodeAutoScaleOptions.pop()
                    this.autoScaleNodesValue = null
                }
            },
            setupAutoScaling() {
                // Is Node Scaling enabled
                let autoScalePolicies = []
                if (this.nodeAutoScaleOptions.length) {
                    if (this.nodeAutoScaleOptions[0].type === this.$config.AUTO_VERTICAL_SCALE) {
                        this.nodeAutoScaleOptions[0].minValue = this.service.size
                    } else {
                        this.nodeAutoScaleOptions[0].minValue = this.service.nodes
                    }
                    autoScalePolicies.push(this.nodeAutoScaleOptions.map(mapToAutoScalePolicyObjects))
                }

                // Is storage Scaling enabled
                if (this.storageAutoScaleOptions.length) {
                    autoScalePolicies.push(this.storageAutoScaleOptions.map(mapToAutoScalePolicyObjects))
                }
                return autoScalePolicies.flat()
            },
            getDeleteActionIds(actions) {
                let deleteActionIds = []

                // Check if previous scaling policy exists to be deleted
                if (this.preSelectedAutoScaleNodePolicy) {
                    let deletePreviousNodeAutoScale = false
                    // If there is new action for Node Scaling
                    // Check if it is not matching with previous policy group
                    // in case it is not matching mark it for deletion
                    const { STORAGE_SCALE_GROUP, } = this.$config
                    const [nodeScalingPolicy] = actions.filter(action => action.group !== STORAGE_SCALE_GROUP)
                    if (nodeScalingPolicy) {
                        deletePreviousNodeAutoScale = nodeScalingPolicy.group !== this.preSelectedAutoScaleNodePolicy.group
                    }

                    if (deletePreviousNodeAutoScale || this.isNodeAutoOptionChecked === false) {
                        deleteActionIds.push(this.preSelectedAutoScaleNodePolicy.id)
                    }
                }

                if (this.preselectedAutoScaleStoragePolicy && this.isStorageAutoOptionChecked === false) {
                    deleteActionIds.push(this.preselectedAutoScaleStoragePolicy.id)
                }

                return deleteActionIds
            },
            updateProjectListForPrivateConnect(projects) {
                this.setPrivateConnect({ serviceId: this.manageServices.serviceId, projects, provider: this.service.provider, })
            },
            filterInstanceCosts() {
                const { provider, topology, architecture, } = this.service
                return getFilteredInstanceCosts(this.instanceCosts, {
                    provider,
                    topology,
                    arch: architecture,
                })
            },
            onIopsChange(val) {
                this.selectedIops = val
            },
            onThroughputChange(val) {
                this.selectedThroughput = val
            },
        },
    }
</script>

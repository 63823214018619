<template>
    <base-dialog
        v-model="isDialogOpen"
        :on-save="submitTax"
        :on-cancel="clearState"
        :min-width="776"
        :max-width="776"
        :on-close="clearState">
        <template #title>
            <div class="font-weight-light text-h5 pb-6">
                {{ dialogTitle }}
            </div>
        </template>
        <template #body>
            <div :class="['pt-6', 'pl-6', 'pr-6', {'disable-dialog': isSaving}]">
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('billings.registration') }}</label>
                        <v-text-field
                            v-model="registration"
                            type="text"
                            required
                            outlined
                            :placeholder="$t('billings.registration')"
                            dense />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('billings.country') }}</label>
                        <v-select
                            v-model="currentCountry"
                            :items="sortedCountries"
                            item-text="name"
                            item-val="iso31662"
                            height="40"
                            :dense="true"
                            class="mt-0 select-usage"
                            outlined
                            return-object
                            @change="changeCountry" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('billings.zipCode') }}</label>
                        <v-text-field
                            v-model="postalCode"
                            outlined
                            type="text"
                            required
                            :placeholder="$t('billings.zipCode')"
                            dense />
                    </v-col>
                </v-row>
            </div>
        </template>
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                color="primary"
                text
                rounded
                class="cancel"
                @click="cancel">
                <span class="text-none">{{ $t('cancel') }} </span>
            </v-btn>
            <v-btn
                color="primary"
                class="save"
                :disabled="!isTaxFormValid"
                depressed
                rounded
                @click="save">
                <span class="text-none"> {{ $t('save') }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        name: 'TaxModal',
        data() {
            return {
                country: 'US',
                registration: null,
                postalCode: null,
                id: null,
                isDialogOpen: false,
                isForEdit: false,
                isSaving: false,
                currentCountry: {},
            }
        },
        computed: {
            ...mapGetters([
                'billingCountries',
                'savingTaxSuccess'
            ]),
            sortedCountries(){
                const clonedCountries = this.$lodash.cloneDeep(this.billingCountries)
                return clonedCountries.sort((a, b) => a.name.localeCompare(b.name))
            },
            isTaxFormValid() {
                return !!this.country
                    && !!this.registration
                    && !!this.postalCode
            },
            dialogTitle() {
                return this.isForEdit ? this.$t('editTax') : this.$t('addTax')
            },
        },
        watch:{
            billingCountries(countries){
                this.setCountry(countries)
            },
        },
        methods: {
            ...mapActions([
                'fetchCountries',
                'fetchTaxes',
                'addNewTax',
                'editTax'
            ]),
            async open({ mode, tax, }) {
                this.isDialogOpen = true
                this.isForEdit = mode === 'edit'
                if (this.isForEdit) {
                    this.country = tax.country
                    this.registration = tax.registration
                    this.postalCode = tax.postalCode
                    this.id = tax.id
                }
                if (this.$lodash.isEmpty(this.billingCountries)) {
                    await this.fetchCountries()
                } else {
                    await this.$nextTick()
                    this.setCountry(this.billingCountries)
                }
            },
            clearState() {
                this.country = 'US'
                this.registration = null
                this.postalCode = null
                this.id = null
                this.isForEdit = false
                this.isSaving = false
                this.currentCountry = {}
                this.isDialogOpen = false
            },
            async saveTax(tax) {
                if (this.isForEdit) {
                    await this.editTax({tax, id: this.id,})
                } else {
                    await this.addNewTax(tax)
                }
            },
            async submitTax() {
                this.isSaving = true
                const tax = {
                    country: this.country,
                    registration: this.registration,
                    postalCode: this.postalCode,
                }
                await this.saveTax(tax)
                this.isSaving = false
                if (this.savingTaxSuccess) {
                    this.clearState()
                    this.$store.commit('showMessage', { text: this.$t('billings.taxSavedSuccessfully'), type: '#03a128', })
                    await this.fetchTaxes()
                } else {
                    this.$store.commit('showMessage', { text: this.$t('errors.saveTax'), type: this.$config.messageType.ERROR, })
                }
            },
            changeCountry(data){
                this.country = data.iso31662
            },
            setCountry(countries) {
                this.country = this.country || 'US'
                this.currentCountry = countries.find(country => country.iso31662 === this.country)
            },
        },
    }
</script>

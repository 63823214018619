<style lang="scss" scoped>
.border-bottom {
    border-bottom: 1px solid $cornsilk;
}

.per-min-cost {
    color: $grayed-out
}
</style>
<template>
    <div v-if="hasChildren">
        <v-row
            v-for="(childNode, index) in node.children"
            :key="index"
            :class="['border-bottom mb-0', { 'mt-0': index }, { 'mt-3': !index }]">
            <v-col
                cols="5"
                class="pl-16 font-weight-regular mt-0 child-title pt-2"
                :data-auto="`lineItem-title-${index}`">
                {{ childNode.title }}
            </v-col>
            <v-col
                class="mt-0 pt-2"
                cols="4">
                <span
                    :data-auto="`lineItem-runTime-${index}`"
                    class="font-weight-regular">{{ childNode.runtime?.toFixed(2) }}</span>
                <span
                    :data-auto="`lineItem-runUnit-${index}`"
                    class="pl-1 font-weight-regular">{{ childNode.unit }}</span>
                <span
                    :data-auto="`lineItem-perMinCost-${index}`"
                    class="ml-6 font-weight-thin per-min-cost">{{
                        $t('billings.atPerUnit',[$t('currency', [childNode.perMinCost?.toFixed(8)]), childNode.rateUnit])
                    }}</span>
            </v-col>
            <v-col
                :data-auto="`lineItem-totalCost-${index}`"
                class="text-right pr-0 pt-2 mt-0">
                {{ $t('currency', [childNode.cost?.toFixed(2)]) }}
            </v-col>
            <v-col cols="2" />
        </v-row>
    </div>
</template>
<script>
    const BillingPanelLeafNodes = {
        name: 'BillingPanelLeafNodes',
        props: {
            node: {
                type: Object,
                required: true,
            },
        },
        computed: {
            hasChildren() {
                const { children, } = this.node
                return children && children.length
            },
        },
    }

    export default BillingPanelLeafNodes
</script>

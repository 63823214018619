<template>
    <base-dialog
        v-model="isDialogOpen"
        :on-save="addDatasource"
        :on-cancel="close"
        :min-width="760"
        :max-width="900"
        :on-close="close">
        <template #title>
            <div class="font-weight-light text-h4 pb-6">
                {{ $t('copilot.manageDatasource') }}
            </div>
        </template>
        <template #body>
            <div>
                <v-data-table
                    class="data-table-full"
                    :headers="headers"
                    :items="datasources"
                    hide-default-footer
                    fixed-header>
                    <template #item="{ item: datasource }">
                        <tr>
                            <td>{{ datasource.name || 'n/a' }}</td>
                            <td>{{ datasource.host || 'n/a' }}</td>
                            <td>{{ datasource.port || 'n/a' }}</td>
                            <td>
                                <div
                                    v-if="datasource.type === 'USER'"
                                    style="white-space: nowrap;">
                                    <v-tooltip
                                        open-delay="400"
                                        top>
                                        <template #activator="{ on }">
                                            <v-btn
                                                color="primary"
                                                icon
                                                v-on="on"
                                                @click="editDatasource(datasource.id)">
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('edit') }}</span>
                                    </v-tooltip>
                                    <v-tooltip
                                        open-delay="400"
                                        top>
                                        <template #activator="{ on }">
                                            <v-btn
                                                color="error"
                                                icon
                                                v-on="on"
                                                @click="confirmDeletion(datasource.id)">
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('delete') }}</span>
                                    </v-tooltip>
                                </div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
        </template>
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                color="primary"
                text
                rounded
                class="cancel"
                @click="cancel">
                <span class="text-none">{{ $t('close') }} </span>
            </v-btn>
            <v-btn
                color="primary"
                class="save"
                style="min-width: 100px;"
                depressed
                rounded
                @click="save">
                <span class="text-none"> {{ $t('addNew') }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'CopilotDatasourcesManageModal',
        data() {
            return {
                isDialogOpen: false,
                headers: [
                    { text: this.$t('name'), value: 'name', sortable: false, },
                    { text: `${this.$t('hostname')} (${this.$t('fqdn')})`, value: 'host', sortable: false, },
                    { text: this.$t('port'), value: 'port', sortable: false, },
                    { text: this.$t('actions'), value: 'actions', sortable: false, }
                ],
            }
        },
        computed: {
            ...mapGetters([
                'datasources'
            ]),
        },
        methods: {
            open() {
                this.isDialogOpen = true
            },
            close() {
                this.isDialogOpen = false
            },
            addDatasource() {
                this.close()
                this.$emit('addDatasource')
            },
            editDatasource(datasourceId) {
                this.close()
                this.$emit('editDatasource', datasourceId)
            },
            confirmDeletion(datasourceId) {
                this.$emit('removeDatasource', datasourceId)
            },
        },
    }
</script>

<template>
    <base-dialog
        v-model="isDialogOpen"
        :on-save="submitContact"
        :on-cancel="clearState"
        :min-width="776"
        :max-width="776"
        :on-close="clearState">
        <template #title>
            <div class="font-weight-light text-h5 pb-6">
                {{ dialogTitle }}
            </div>
        </template>
        <template #body>
            <div :class="['pt-6', 'pl-6', 'pr-6', {'disable-dialog': isSaving}]">
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('billings.firstName') }}</label>
                        <v-text-field
                            v-model="firstName"
                            type="text"
                            required
                            outlined
                            :placeholder="$t('billings.firstName')"
                            dense />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('billings.lastName') }}</label>
                        <v-text-field
                            v-model="lastName"
                            type="text"
                            required
                            outlined
                            :placeholder="$t('billings.lastName')"
                            dense />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0">
                        <label class="required text-uppercase">{{ $t('billings.phoneNumber') }}</label>
                        <VueTelInput
                            v-model="phone"
                            mode="auto"
                            @input="onPhoneInput" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-0">
                        <label class="required text-uppercase">{{ $t('email') }}</label>
                        <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            outlined
                            type="email"
                            required
                            :placeholder="$t('email')"
                            dense />
                    </v-col>
                </v-row>
            </div>
        </template>
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                color="primary"
                text
                rounded
                class="cancel"
                @click="cancel">
                <span class="text-none">{{ $t('cancel') }} </span>
            </v-btn>
            <v-btn
                color="primary"
                class="save"
                :disabled="!isContactFormValid"
                depressed
                rounded
                @click="save">
                <span class="text-none"> {{ $t('save') }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import { VueTelInput } from 'vue-tel-input'
    import 'vue-tel-input/dist/vue-tel-input.css'

    export default {
        name: 'ContactModal',
        components: {
            VueTelInput,
        },
        data() {
            return {
                firstName: null,
                lastName: null,
                phone: null,
                email: null,
                id: null,
                isDialogOpen: false,
                isForEdit: false,
                isSaving: false,
                emailRules: this.$help.emailInputRules(false),
            }
        },
        computed: {
            ...mapGetters([
                'savingContactSuccess'
            ]),
            isContactFormValid() {
                return !!this.firstName
                    && !!this.lastName
                    && !!this.phone
                    && !!this.email
                    && this.isEmailValid
            },
            isEmailValid() {
                return this.emailRules[3](this.email) === true
            },
            dialogTitle() {
                return this.isForEdit ? this.$t('editContact') : this.$t('addContact')
            },
        },
        methods: {
            ...mapActions([
                'fetchContacts',
                'addNewContact',
                'editContact'
            ]),
            async open({ mode, contact, }) {
                this.isDialogOpen = true
                this.isForEdit = mode === 'edit'
                if (this.isForEdit) {
                    this.firstName = contact.firstName
                    this.lastName = contact.lastName
                    this.phone = contact.phone
                    this.email = contact.email
                    this.id = contact.id
                }
            },
            clearState() {
                this.firstName = null
                this.lastName = null
                this.phone = null
                this.email = null
                this.id = null
                this.isForEdit = false
                this.isSaving = false
                this.isDialogOpen = false
            },
            async saveContact(contact) {
                if (this.isForEdit) {
                    await this.editContact({contact, id: this.id,})
                } else {
                    await this.addNewContact(contact)
                }
            },
            async submitContact() {
                this.isSaving = true
                const contact = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    phone: this.phone,
                    email: this.email,
                }
                await this.saveContact(contact)
                this.isSaving = false
                if (this.savingContactSuccess) {
                    this.clearState()
                    this.$store.commit('showMessage', { text: this.$t('billings.contactSavedSuccessfully'), type: '#03a128', })
                    await this.fetchContacts()
                } else {
                    this.$store.commit('showMessage', { text: this.$t('errors.saveContact'), type: this.$config.messageType.ERROR, })
                }
            },
            onPhoneInput(_, phoneObject) {
                this.phone = phoneObject.number
            },
        },
    }
</script>

<template>
    <v-select
        :class="['std std--dropdown select-dropdown']"
        :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
        :items="datasources"
        item-text="name"
        item-value="id"
        :value="selectedDatasource?.id"
        :loading="areDatasourcesLoading"
        :disabled="areDatasourcesLoading"
        :error="datasourcesError"
        height="36"
        outlined
        attach
        single-line
        @change="changeDatasource" />
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        name: 'CopilotDatasourcesMenu',
        computed: {
            ...mapGetters([
                'datasources',
                'datasourcesError',
                'areDatasourcesLoading',
                'selectedDatasource'
            ]),
        },
        watch: {
            datasources(datasourcesList) {
                if (datasourcesList && datasourcesList.length) {
                    this.selectDatasource(datasourcesList[0].id)
                }
            },
        },
        methods: {
            ...mapActions([
                'selectDatasource'
            ]),
            changeDatasource(datasourceId) {
                this.selectDatasource(datasourceId)
            },
        },
    }
</script>

<template>
    <base-dialog
        v-model="isDialogOpen"
        :on-save="submitAddress"
        :on-cancel="clearState"
        :min-width="776"
        :max-width="776"
        :on-close="clearState">
        <template #title>
            <div class="font-weight-light text-h5 pb-6">
                {{ dialogTitle }}
            </div>
        </template>
        <template #body>
            <div :class="['pt-6', 'pl-6', 'pr-6', {'disable-dialog': isSaving}]">
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('type') }}</label>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <v-checkbox
                            v-model="isShipping"
                            :disabled="isForEdit"
                            :label="$t('billings.shipping')" />
                    </v-col>
                    <v-col class="pt-0 pb-0">
                        <v-checkbox
                            v-model="isBilling"
                            :disabled="isForEdit"
                            :label="$t('billings.billing')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('billings.address1') }}</label>
                        <v-text-field
                            v-model="addressLine1"
                            type="text"
                            required
                            outlined
                            :placeholder="$t('billings.address1')"
                            dense />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <label class="text-uppercase">{{ $t('billings.address2') }}</label>
                        <v-text-field
                            v-model="addressLine2"
                            type="text"
                            outlined
                            :placeholder="$t('billings.address2')"
                            dense />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('billings.country') }}</label>
                        <v-select
                            v-model="currentCountry"
                            :items="sortedCountries"
                            item-text="name"
                            item-val="iso31662"
                            height="40"
                            :dense="true"
                            class="mt-0 select-usage"
                            outlined
                            return-object
                            @change="changeCountry" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('billings.stateProvince') }}</label>
                        <v-select
                            v-model="currentState"
                            :items="sortedStates"
                            item-text="name"
                            item-val="iso31662"
                            height="40"
                            :dense="true"
                            class="mt-0 select-usage"
                            outlined
                            return-object
                            @change="changeState" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('billings.city') }}</label>
                        <v-text-field
                            v-model="city"
                            outlined
                            type="text"
                            required
                            :placeholder="$t('billings.city')"
                            dense />
                    </v-col>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('billings.zipCode') }}</label>
                        <v-text-field
                            v-model="postalCode"
                            outlined
                            type="text"
                            required
                            :placeholder="$t('billings.zipCode')"
                            dense />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0">
                        <label class="required text-uppercase">{{ $t('billings.phoneNumber') }}</label>
                        <VueTelInput
                            v-model="phone"
                            mode="auto"
                            @input="onPhoneInput" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-0">
                        <label class="required text-uppercase">{{ $t('email') }}</label>
                        <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            outlined
                            type="email"
                            required
                            :placeholder="$t('email')"
                            dense />
                    </v-col>
                </v-row>
            </div>
        </template>
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                color="primary"
                text
                rounded
                class="cancel"
                @click="cancel">
                <span class="text-none">{{ $t('cancel') }} </span>
            </v-btn>
            <v-btn
                color="primary"
                class="save"
                :disabled="!isAddressFormValid"
                depressed
                rounded
                @click="save">
                <span class="text-none"> {{ $t('save') }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import { VueTelInput } from 'vue-tel-input'
    import 'vue-tel-input/dist/vue-tel-input.css'

    export default {
        name: 'AddressModal',
        components: {
            VueTelInput,
        },
        data() {
            return {
                addressLine1: null,
                addressLine2: '',
                country: 'US',
                state: null,
                city: null,
                postalCode: null,
                phone: null,
                email: null,
                currentCountry: {},
                currentState: {},
                isSaving: false,
                isDialogOpen: false,
                isForEdit: false,
                emailRules: this.$help.emailInputRules(false),
                isShipping: true,
                isBilling: false,
                id: null,
            }
        },
        computed: {
            ...mapGetters([
                'billingCountries',
                'billingStates',
                'savingAddressSuccess'
            ]),
            sortedCountries(){
                const clonedCountries = this.$lodash.cloneDeep(this.billingCountries)
                return clonedCountries.sort((a, b) => a.name.localeCompare(b.name))
            },
            sortedStates() {
                const clonedStates = JSON.parse(JSON.stringify(this.billingStates))
                clonedStates.sort((a, b) => a.name.localeCompare(b.name))
                if (this.$typy(this.currentCountry, 'name').safeString) {
                    clonedStates.filter(state => state.country === this.currentCountry.name)
                }
                return clonedStates
            },
            isAddressFormValid() {
                return !!this.addressLine1
                    && !!this.country
                    && !!this.state
                    && !!this.city
                    && !!this.postalCode
                    && !!this.phone
                    && !!this.email
                    && this.isEmailValid
                    && (this.isShipping || this.isBilling)
            },
            dialogTitle() {
                return this.isForEdit ? this.$t('editAddress') : this.$t('addAddress')
            },
            isEmailValid() {
                return this.emailRules[3](this.email) === true
            },
        },
        watch:{
            billingCountries(countries){
                this.setCountryAndState(countries)
            },
            billingStates(states){
                if(!this.state) return
                this.currentState = states.find(state => state.isoShort === this.state)
            },
        },
        methods: {
            ...mapActions([
                'fetchCountries',
                'fetchStatesForCountry',
                'fetchAddresses',
                'addNewAddress',
                'editAddress'
            ]),
            async open({ mode, address, }) {
                this.isDialogOpen = true
                this.isForEdit = mode === 'edit'
                if (this.isForEdit) {
                    this.addressLine1 = address.addressLine1
                    this.addressLine2 = address.addressLine2
                    this.country = address.country
                    this.state = address.state
                    this.city = address.city
                    this.postalCode = address.postalCode
                    this.phone = address.phone
                    this.email = address.email
                    this.isShipping = address.addressType === 'SHIPPING'
                    this.isBilling = address.addressType === 'BILLING'
                    this.id = address.id
                }
                if (this.$lodash.isEmpty(this.billingCountries)) {
                    await this.fetchCountries()
                } else {
                    await this.$nextTick()
                    this.setCountryAndState(this.billingCountries)
                }
            },
            clearState() {
                this.addressLine1 = null
                this.addressLine2 = ''
                this.country = 'US'
                this.state = null
                this.city = null
                this.postalCode = null
                this.phone = null
                this.email = null
                this.currentCountry = {}
                this.currentState = {}
                this.isForEdit = false
                this.isSaving = false
                this.id = null
                this.isSaving = false
                this.isShipping = true
                this.isBilling = false
                this.isDialogOpen = false
            },
            async saveAddress(address) {
                if (this.isForEdit) {
                    await this.editAddress({address, id: this.id,})
                } else {
                    await this.addNewAddress(address)
                }
            },
            async submitAddress() {
                this.isSaving = true
                const address = {
                    addressLine1: this.addressLine1,
                    addressLine2: this.addressLine2,
                    country: this.country,
                    state: this.state,
                    city: this.city,
                    postalCode: this.postalCode,
                    phone: this.phone,
                    email: this.email,
                }
                if (this.isShipping) {
                    address.addressType = 'SHIPPING'
                    await this.saveAddress(address)
                }
                if (this.isBilling) {
                    address.addressType = 'BILLING'
                    await this.saveAddress(address)
                }
                this.isSaving = false
                if (this.savingAddressSuccess) {
                    this.clearState()
                    this.$store.commit('showMessage', { text: this.$t('billings.addressSavedSuccessfully'), type: '#03a128', })
                    await this.fetchAddresses()
                } else {
                    this.$store.commit('showMessage', { text: this.$t('errors.saveAddress'), type: this.$config.messageType.ERROR, })
                }
            },
            changeCountry(data){
                this.country = data.iso31662
                this.fetchStatesForCountry({country: data.iso31662,})
            },
            changeState(data){
                this.state = data.isoShort
            },
            onPhoneInput(_, phoneObject) {
                this.phone = phoneObject.number
            },
            setCountryAndState(countries) {
                this.country = this.country || 'US'
                this.currentCountry = countries.find(country => country.iso31662 === this.country)
                this.fetchStatesForCountry({country: this.country,})
            },
        },
    }
</script>

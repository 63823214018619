<style lang="scss">
iframe {
    width: 100% !important;
}

.field-required {
    &:before {
        content: "* ";
        color: $required-asterisk;
    }
}

.otp-wrapper {
    width: 300px;
}

.edit-phone-number{
    min-height: 200px;
}
.StripeElement {
    border: 1px solid #00000061;
    border-radius: 4px;
    height: 38px;
    padding: 8px;
}

.StripeElement--focused {
    border: 2px solid rgb(99, 83, 239)
}

.StripeElement--invalid {
    border: 1px solid #D22F27
}

.info-text {
    color: rgb(99, 83, 239);
    font-size: smaller;
    line-height: normal;
}
</style>

<template>
    <div>
        <div class="payment-option">
            <base-dialog
                v-model="isPaymentOpen"
                :min-width="800"
                :max-width="800"
                :title="$t('billings.addNewCreditCard')"
                :on-close="closePaymentModal"
                :on-cancel="closePaymentModal">
                <template #body>
                    <div :class="['pt-6', 'pl-6', 'pr-6', {'disable-dialog': isSaving}]">
                        <div class="mb-7">
                            {{ $t('billings.paymentNote') }}
                        </div>
                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <label class="required text-uppercase">{{ $t('billings.firstName') }}</label>
                                <v-text-field
                                    v-model="firstName"
                                    outlined
                                    dense />
                            </v-col>
                            <v-col class="pt-0 pb-0">
                                <label class="required text-uppercase">{{ $t('billings.lastName') }}</label>
                                <v-text-field
                                    v-model="lastName"
                                    outlined
                                    dense />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="py-0"
                                cols="5">
                                <label class="required text-uppercase">{{ $t('billings.cardNumber') }}</label>
                                <div id="card-number"></div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="2"
                                class="mb-2 mt-1">
                                <label class="required text-uppercase">{{ `${$t('billings.month')}/${$t('billings.year')}` }}</label>
                                <div id="card-expiry"></div>
                            </v-col>
                            <v-col
                                cols="2"
                                class="mb-2 mt-1">
                                <label class="required text-uppercase">{{ $t('billings.cvv') }}</label>
                                <div id="card-cvc"></div>
                            </v-col>
                            <v-col class="pt-8 px-0 d-flex align-center">
                                <v-icon
                                    size="16"
                                    color="rgb(99, 83, 239)">
                                    mdi-information-outline
                                </v-icon>
                                <span class="ml-1 info-text">{{ $t('billings.ccDetailsInfo') }}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <label class="required text-uppercase">{{ $t('billings.address1') }}</label>
                                <v-text-field
                                    v-model="address"
                                    outlined
                                    :placeholder="$t('billings.address1')"
                                    dense />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <label class="text-uppercase">{{ $t('billings.address2') }}</label>
                                <v-text-field
                                    v-model="address2"
                                    outlined
                                    :placeholder="$t('billings.address2')"
                                    dense />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <label class="required text-uppercase">{{ $t('billings.country') }}</label>
                                <v-select
                                    v-model="currentCountry"
                                    :items="sortedCountries"
                                    item-text="name"
                                    item-val="iso31662"
                                    height="40"
                                    :dense="true"
                                    class="mt-0 select-usage"
                                    outlined
                                    return-object
                                    @change="changeCountry" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <label class="required text-uppercase">{{ $t('billings.stateProvince') }}</label>
                                <v-select
                                    v-model="currentState"
                                    :items="sortedStates"
                                    item-text="name"
                                    item-val="iso31662"
                                    height="40"
                                    :dense="true"
                                    class="mt-0 select-usage"
                                    outlined
                                    return-object
                                    @change="changeState" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <label class="required text-uppercase">{{ $t('billings.city') }}</label>
                                <v-text-field
                                    v-model="city"
                                    outlined
                                    :placeholder="$t('billings.city')"
                                    dense />
                            </v-col>
                            <v-col class="pt-0 pb-0">
                                <label class="required text-uppercase">{{ $t('billings.zipCode') }}</label>
                                <v-text-field
                                    v-model="zip"
                                    outlined
                                    :placeholder="$t('billings.zipCode')"
                                    dense />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <label class="text-uppercase">{{ $t('billings.vatNumber') }}</label>
                                <v-text-field
                                    v-model="vatNumber"
                                    outlined
                                    :placeholder="$t('billings.vatNumber')"
                                    dense />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <label class="required text-uppercase">{{ $t('billings.phoneNumber') }}</label>
                                <VueTelInput
                                    v-model="phone"
                                    mode="auto"
                                    @input="onPhoneInput" />
                            </v-col>
                        </v-row>
                    </div>
                </template>
                <template #actions="{ cancel }">
                    <v-spacer />

                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        outlined
                        class="cancel"
                        @click="cancel">
                        <span class="text-none">{{ $t('cancel') }} </span>
                    </v-btn>
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        :disabled="!isPaymentformValid"
                        :loading="isSaving"
                        @click.prevent="submitCCData">
                        <span class="text-none"> {{ $t('save') }}</span>
                    </v-btn>
                </template>
            </base-dialog>
        </div>
    </div>
</template>

<script>
    import to from 'await-to-js'
    import { setupIntent } from 'services/billings'
    import { mapGetters, mapMutations, mapActions } from 'vuex'
    import { VueTelInput } from 'vue-tel-input'
    import 'vue-tel-input/dist/vue-tel-input.css'

    const elementStyles = {
        base: {
            color: '#32325D',
            fontWeight: 500,
            fontFamily: '"Azo Sans", sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            '::placeholder': {
                color: '#afb5ba',
            },
            ':-webkit-autofill': {
                color: '#e39f48',
            },
        },
        invalid: {
            color: '#E25950',
            '::placeholder': {
                color: '#FFCCA5',
            },
        },
    }
    const elementClasses = {
        focus: 'StripeElement--focused',
        invalid: 'StripeElement--invalid',
    }

    export default {
        name: 'Payment',
        components: {
            VueTelInput,
        },
        props: {
            legalEntityIdentifier: {
                type: String,
                required: true,
            },
            product: {
                type: String,
                default: '',
            },
            primaryContactCountry: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                firstName: null,
                lastName: null,
                address: null,
                address2: '',
                country: null,
                state: null,
                city: null,
                zip: null,
                vatNumber: '',
                phone: null,
                currentCountry: {},
                currentState: {},
                isSaving: false,
                stripe: null,
                elements: null,
                cardNumber: null,
                intent: null,
                publishableKey: null,
                returnUrl: null,
                secret: null,
                isCardNumberValid: false,
                isCardExpiryValid: false,
                isCardCvcValid: false,
            }
        },
        computed: {
            ...mapGetters([
                'isPaymentDialogOpen',
                'user',
                'billingCountries',
                'billingStates',
                'orgData',
                'isFetchingPaymentDetail',
                'updateProfileSuccess',
                'billingRecord'
            ]),
            isPaymentOpen: {
                get() {
                    return this.isPaymentDialogOpen
                },
                set(value) {
                    this.setPaymentDialog(value)
                },
            },
            sortedCountries(){
                const clonedCountries = JSON.parse(JSON.stringify(this.billingCountries))
                return clonedCountries.sort((a, b) => a.name.localeCompare(b.name))
            },
            sortedStates() {
                const clonedStates = JSON.parse(JSON.stringify(this.billingStates))
                clonedStates.sort((a, b) => a.name.localeCompare(b.name))
                if (this.$typy(this.currentCountry, 'name').safeString) {
                    clonedStates.filter(state => state.country === this.currentCountry.name)
                }
                return clonedStates
            },
            isPaymentformValid() {
                return !!this.firstName && !!this.lastName && this.isCardValid && !!this.address && !!this.country && !!this.state && !!this.city && !!this.zip && !!this.phone
            },
            isCardValid() {
                return this.isCardNumberValid && this.isCardExpiryValid && this.isCardCvcValid
            },
        },
        watch:{
            billingCountries(countries){
                this.currentCountry = countries.find(country => country.iso31662 === this.country)
                this.fetchStatesForCountry({country: this.country,})
            },
            isPaymentDialogOpen(value){
                if(value) {
                    this.$nextTick(async ()=>{
                        await this.loadStripe()
                    })
                }
            },
            billingStates(states){
                if(!this.state) return
                this.currentState = states.find(state => state.isoShort === this.state)
            },
        },
        methods: {
            ...mapMutations(['setPaymentDialog']),
            ...mapActions([
                'fetchCountries',
                'fetchStatesForCountry',
                'registerPaymentProfile',
                'updateBillingRecordPhoneNumber'
            ]),
            async submitCCData() {
                this.isSaving = true
                // eslint-disable-next-line no-unused-vars
                const [error, response] = await to(this.stripe.confirmCardSetup(this.secret, {
                    'payment_method': {
                        card: this.cardNumber,
                        'billing_details': {
                            name: `${this.firstName} ${this.lastName}`,
                            address: {
                                line1: this.address,
                                line2: this.address2,
                                city: this.city,
                                state: this.state,
                                country: this.country,
                                'postal_code': this.zip,
                            },
                        },
                    },
                }
                ))
                if(response?.setupIntent) {
                    let id = response?.setupIntent?.id ?? ''
                    this.pushSentryEvent('Card added successfully', 'info')
                    this.updateProfileInfo(id)
                }
                else {
                    this.$store.commit('showMessage', { text: response?.error?.message ?? this.$t('billings.cardSetupError'), type: this.$config.messageType.ERROR, })
                    this.pushSentryEvent(response?.error?.message ?? this.$t('billings.cardSetupError'), 'error')
                }
                this.isSaving = false
            },
            changeCountry(data){
                this.country = data.iso31662
                this.fetchStatesForCountry({country: data.iso31662,})
            },
            changeState(data){
                this.state = data.isoShort
            },
            async updateProfileInfo(intentId) {
                this.isSaving = true
                let profileObject = {
                    token: intentId,
                    billingDetails: {
                        ...this.$lodash.pick(this, [
                            'firstName',
                            'lastName',
                            'phone',
                            'address',
                            'address2',
                            'city',
                            'state',
                            'vatNumber',
                            'zip',
                            'country'
                        ]),
                        email: this.user.email,
                    },
                }
                await this.registerPaymentProfile(profileObject)
                this.isSaving = false
                if (this.updateProfileSuccess) {
                    this.$store.commit('showMessage', { text: this.$t('billings.cardAddedSuccessfully'), type: '#03a128', })
                    this.pushSentryEvent('Payment profile added successfully', 'info')
                    return this.$emit('on-update-profile')
                }
                this.$store.commit('showMessage', { text: this.$t('billings.errorUpdatingPaymentProfile'), type: this.$config.messageType.ERROR, })
                this.pushSentryEvent(this.$t('billings.errorUpdatingPaymentProfile'), 'error')
            },
            setupBillingDetails() {
                this.firstName = this.user.firstName
                this.lastName = this.user.lastName
                this.country = 'US'
                this.currentCountry = this.billingCountries?.find(country => country.iso31662 === this.country)
                this.phone = this.billingRecord?.primaryContact?.primaryContactPhone ?? ''
            },
            closePaymentModal() {
                this.$emit('on-payment-close')
            },
            onPhoneInput(_, phoneObject) {
                this.phone = phoneObject.number
            },
            async loadStripe() {
                this.stripe = new Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
                this.elements = await this.stripe.elements({})
                this.cardNumber = await this.elements.create('cardNumber', {
                    style: elementStyles,
                    classes: elementClasses,
                    showIcon: true,
                })
                this.cardNumber.mount('#card-number')
                this.cardNumber.on('change', (event) => {
                    this.setElementValidationStatus(event)
                })

                const cardExpiry = await this.elements.create('cardExpiry', {
                    style: elementStyles,
                    classes: elementClasses,
                })
                cardExpiry.mount('#card-expiry')
                cardExpiry.on('change', (event) => {
                    this.setElementValidationStatus(event)
                })

                const cardCvc = await this.elements.create('cardCvc', {
                    style: elementStyles,
                    classes: elementClasses,
                })
                cardCvc.mount('#card-cvc')
                cardCvc.on('change', (event) => {
                    this.setElementValidationStatus(event)
                })
            },
            setElementValidationStatus(event) {
                switch(event?.elementType) {
                    case 'cardNumber':
                        this.isCardNumberValid = event?.complete
                        break
                    case 'cardExpiry':
                        this.isCardExpiryValid = event?.complete
                        break
                    case 'cardCvc':
                        this.isCardCvcValid = event?.complete
                        break
                    default:
                }
            },
            pushSentryEvent(msg, eventLevel) {
                this.$sentry.captureEvent({ message: msg, user: { id: this.user.id, username: this.user.name, }, level: eventLevel,})
            },
        },
        async mounted(){
            this.setupBillingDetails()
        },
        async created() {
            const [error, response] = await to(setupIntent())
            if (!error) {
                ({intent: this.intent, returnUrl: this.returnUrl, secret: this.secret,} = response)
            } else {
                this.$store.commit('showMessage', { text: this.$t('billings.setupIntentError'), type: this.$config.messageType.ERROR, })
                this.pushSentryEvent(this.$t('billings.setupIntentError'), 'error')
            }
        },
    }
</script>

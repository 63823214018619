<style lang="scss" scoped>
    .message {
        width: 100%;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        padding: 20px 0;
        &.system {
            align-items: flex-start;
        }
        &.user > div {
            background: #ddd;
            padding: 12px 24px;
            border-radius: 20px;
        }
        &.system > div {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
        > div {
            max-width: 70%;
        }
    }
    .input {
        width: 100%;
        max-width: 768px;
        margin: 0 auto;
    }
    .my-editor {
        height: auto;
        background: #ddd;
        font-size: 14px;
        line-height: 1.5;
        margin-top: 8px;
        padding: 10px 16px;
        border-radius: 10px;
        margin-top: 8px;
    }
    .controls {
        display: flex;
        margin-top: 4px;
        justify-content: space-between;
    }
    .controls > div {
        display: flex;
        gap: 4px;
    }
</style>

<template>
    <div>
        <div class="message user">
            <div>{{ message.prompt }}</div>
        </div>
        <div
            v-if="message.response"
            class="message system">
            <img
                src="@/assets/logo-symbol.avif"
                height="24" />
            <div>
                <div>
                    <vue-markdown :source="message.response.content" />
                </div>
                <div v-if="message.response.sqlText?.length || message.response.errorText?.length">
                    <v-tabs>
                        <v-tab v-if="message.response.sqlText?.length">
                            SQL
                        </v-tab>
                        <v-tab v-if="message.response.errorText?.length">
                            Error
                        </v-tab>
                        <v-tab-item v-if="message.response.sqlText">
                            <pre>
                                <code class="language-sql">{{ message.response.sqlText }}</code>
                            </pre>
                        </v-tab-item>
                        <v-tab-item v-if="message.response.errorText">
                            {{ message.response.errorText }}
                        </v-tab-item>
                    </v-tabs>
                </div>
                <div class="controls">
                    <div>
                        <v-btn
                            icon
                            disabled
                            x-small>
                            <v-icon>mdi-thumb-up</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            disabled
                            x-small>
                            <v-icon>mdi-thumb-down</v-icon>
                        </v-btn>
                        <v-tooltip
                            v-if="message.response.sqlText?.length"
                            max-width="300"
                            top>
                            <template #activator="{ on }">
                                <v-btn
                                    text
                                    x-small
                                    disabled
                                    v-on="on">
                                    <v-icon>mdi-bookmark-outline</v-icon>
                                    Mark as golden
                                </v-btn>
                            </template>
                            <div>
                                This will tell the agent to perceive this query as "golden" example which will segnificantly increase the accuracy for relevent queries.
                            </div>
                        </v-tooltip>
                    </div>
                    <div
                        v-if="message.response.sqlText?.length"
                        class="context">
                        <v-tooltip top>
                            <template #activator="{ on }">
                                <v-btn
                                    icon
                                    x-small
                                    disabled
                                    v-on="on">
                                    <v-icon>mdi-play-box</v-icon>
                                </v-btn>
                            </template>
                            <div>
                                Run Query
                            </div>
                        </v-tooltip>
                        <v-tooltip top>
                            <template #activator="{ on }">
                                <v-btn
                                    icon
                                    x-small
                                    disabled
                                    v-on="on">
                                    <v-icon>mdi-information</v-icon>
                                </v-btn>
                            </template>
                            <div>
                                Explain Query
                            </div>
                        </v-tooltip>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueMarkdown from 'vue-markdown-render'

    export default {
        name: 'CopilotChatMessage',
        components: {
            VueMarkdown,
        },
        props: {
            message: {
                type: Object,
                required: true,
            },
        },
    }
</script>

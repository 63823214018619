<style lang="scss" scoped>
.billing-summary {
    border: 1px solid $cornsilk;
    color: $deep-ocean;

    & > .col:not(:last-of-type) {
        border-right: 1px solid $cornsilk;
    }
}
</style>

<template>
    <div>
        <div v-if="!isFetchingBillingUsage">
            <div v-if="Object.keys(billingUsageDetails).length">
                <v-row
                    data-auto="billing-summary"
                    class="billing-summary pa-2">
                    <v-col cols="8">
                        {{ $t('billings.current-charges') }}
                        <div
                            data-auto="total-charges"
                            class="font-weight-bold text-subtitle-1">
                            {{ $t('currency', [total.toFixed(2)]) }}*
                        </div>
                        <div class="text-caption font-italic">
                            {{ $t('billings.estimateOnlyDescription') }}
                        </div>
                    </v-col>
                    <template v-if="$unleash.isFeatureEnabled('enable-serverless')">
                        <v-col cols="2">
                            <div class="align-center d-flex">
                                <span class="mr-1">
                                    {{ $t('billings.scuHoursConsumed') }}
                                </span>
                                <ScuHelpTooltip />
                            </div>
                            <div
                                data-auto="scu-hours-consumed"
                                class="font-weight-bold text-subtitle-1">
                                {{ scuConsumed?.toFixed(2) }}
                            </div>
                        </v-col>
                        <v-col cols="2">
                            <div class="align-center d-flex">
                                <span class="mr-1">
                                    {{ $t('billings.scuHoursRemaining') }}
                                </span>
                                <ScuHelpTooltip />
                            </div>
                            <div
                                data-auto="scu-hours-remaining"
                                class="font-weight-bold text-subtitle-1">
                                {{ scuRemaining?.toFixed(2) }}
                            </div>
                        </v-col>
                    </template>
                </v-row>
                <div
                    class="mt-4">
                    <BillingUsageTable
                        :current-usage-sort="currentUsageSort"
                        :usage-data="usageData" />
                    <v-row>
                        <v-col cols="8" />
                        <BillingUsageTotal
                            :total="total" />
                        <v-col cols="2" />
                    </v-row>
                </div>
            </div>
            <div v-else>
                <v-row
                    justify="center"
                    data-auto="billing-summary"
                    class="font-weight-bold text-subtitle-1 billing-summary pa-2">
                    {{ $t('errors.missingUsageData') }}
                </v-row>
            </div>
        </div>
        <v-container
            v-else
            class="fill-height"
            fluid>
            <v-row
                align="center"
                justify="center">
                <v-progress-circular
                    size="70"
                    width="7"
                    color="primary"
                    indeterminate />
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import BillingSortToggle from 'components/billing/BillingSortToggle.vue'
    import BillingUsageTable from 'components/billing/BillingUsageTable.vue'
    import BillingUsageTotal from 'components/billing/BillingUsageTotal.vue'
    import ScuHelpTooltip from 'components/common/ScuHelpTooltip.vue'
    import { prepareUsageByEntities, getTopologyTitle } from 'utils/billing'
    import i18n from 'plugins/i18n'
    const BillingSummary = {
        name: 'BillingSummary',
        components: {
            ScuHelpTooltip,
            BillingSortToggle,
            BillingUsageTable,
            BillingUsageTotal,
        },
        props: {
            lastBilled: {
                required: true,
                type: String,
            },
            nextInvoice: {
                required: true,
                type: String,
            },
        },
        data() {
            return {
                currentUsageSort: 'service',
            }
        },
        computed: {
            ...mapGetters([
                'billingUsageDetails',
                'orgData',
                'isFetchingBillingUsage'
            ]),
            usageSort() {
                return [{
                    text: 'By service',
                    value: 'service',
                }, {
                    text: 'By resource',
                    value: 'resource',
                }]
            },
            usageData(){
                if(this.$lodash.isEmpty(this.billingUsageDetails?.services)) return []
                return this.currentUsageSort === 'service' ? this.transformUsageByServices(this.billingUsageDetails?.services) : prepareUsageByEntities(this.billingUsageDetails)
            },
            total() {
                return this.billingUsageDetails?.invoiceAmount ?? 0
            },
            scuConsumed() {
                return this.billingUsageDetails?.scuConsumed ?? 0
            },
            scuRemaining() {
                return this.billingUsageDetails?.scuRemaining ?? 0
            },
        },
        methods: {
            ...mapActions(['fetchBillingUsage', 'fetchBillingRecord']),
            updateUsageSort(sortType){
                this.currentUsageSort = sortType
            },
            transformUsageByServices(usageData) {
                let usage = []
                usageData?.forEach(o => {
                    let obj = {}
                    obj.title = o?.service?.name ?? '',
                    obj.subTitle = i18n.t('billings.topologyOnProvider', { topology: getTopologyTitle(o?.service?.topology), provider: o?.service?.provider.toUpperCase(), }),
                    obj.cost = o?.cost
                    obj.children = [
                        {
                            title: 'Nodes',
                            cost: o?.nodeCost,
                            children: o?.nodes.map(node => ({
                                title: node?.size.charAt(0).toUpperCase() + node?.size.slice(1),
                                ...this.getAttributes(node),
                            })),
                        },
                        {
                            title: 'Storage',
                            cost: o?.storage?.totalCost,
                            children: [
                                {
                                    title: o?.storage?.type.toUpperCase(),
                                    ...this.getAttributes( o?.storage),
                                },
                                ...o?.storage?.iops ? [{
                                    title: 'IOPS',
                                    ...this.getAttributes( o?.storage?.iops),
                                }] : [],
                                ...o?.storage?.throughput ? [{
                                    title: 'Throughput',
                                    ...this.getAttributes( o?.storage?.throughput),
                                }] : []
                            ],
                        }
                    ]
                    usage.push(obj)
                })
                return usage
            },
            getAttributes(object) {
                return {
                    runtime: object?.quantity,
                    unit: object?.unit,
                    perMinCost: object?.rate,
                    cost: object?.cost,
                    rateUnit: object?.rateUnit,
                }
            },
        },
        created() {
            const monthStartDate = this.$moment().startOf('month').format('YYYY-MM-DD')
            if (this.$lodash.isEmpty(this.billingUsageDetails)) {
                // eslint-disable-next-line camelcase
                this.fetchBillingUsage({ orgId: this.orgData.org_id, startDate: monthStartDate, })
            }
        },
    }
    export default BillingSummary
</script>

<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-col cols="auto pa-0 wrapper-container">
            <SettingHeader
                :header-text="routeName"
                class="mb-10" />
            <div v-if="$route.name === 'Address Management'">
                <div class="d-flex justify-space-between">
                    <div class="d-flex">
                        <h3 class="font-weight-medium">
                            {{ $t('billings.savedAddresses') }}
                        </h3>
                        <v-tooltip top>
                            <template #activator="{ on }">
                                <v-icon
                                    size="21"
                                    class="ml-2"
                                    v-on="on">
                                    mdi-help-circle-outline
                                </v-icon>
                            </template>
                            <span>{{ $t('billings.addressManagementTip') }}</span>
                        </v-tooltip>
                    </div>
                </div>
                <AddressTable
                    :loading="isFetchingAddresses"
                    :addresses="addresses" />
            </div>
            <router-view v-else />
        </v-col>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import AddressTable from 'pages/settings/addressManagement/AddressTable.vue'
    import SettingHeader from 'pages/settings/SettingHeader.vue'

    export default {
        name: 'AddressManagement',
        components: {
            AddressTable,
            SettingHeader,
        },
        data() {
            return {
                routeName: this.$route.name ? this.$route.name : this.$route.params.configName,
            }
        },
        computed: {
            ...mapGetters([
                'addresses',
                'addressesError',
                'isFetchingAddresses'
            ]),
        },
        watch: {
            $route(to) {
                this.routeName = to.name ? to.name : to.params.configName
            },
        },
        methods: {
            ...mapActions(['fetchAddresses']),
        },
        async created() {
            await this.fetchAddresses()
        },
    }
</script>
